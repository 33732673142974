import moment from "moment";

export function formatNumber(number : number, amountFormat?: string, amountSeparator?: string) { 
  var formatedNumber;
  if(amountFormat?.includes('.')){
    var decimalSize = amountFormat?.split('.')[1].length;
    formatedNumber = addSeparator(Number(number).toFixed(decimalSize),amountSeparator);
  }
  else{
    formatedNumber = addSeparator(number.toString(), amountSeparator);
  }
  return formatedNumber?.toString();
};

function addSeparator(formatedNumber : string, amountSeparator?: string) {
  //this regular expression adds separator giving a symbol for thousands
  if(amountSeparator !=""  && amountSeparator != null)
    return formatedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, amountSeparator);
};

export function dateAsInternalToDisplayFormat(
  date: Date,
  displayDateFormat: string | undefined
): string {
  return displayDateFormat
    ? moment(date).format(displayDateFormat.toUpperCase()).toString()
    : date.toString();
}

export const INTERNAL_CHATBOT_DATE_FORMAT = 'MM/DD/YYYY';
