import { EcommerceAddress } from "./ecommerceAddress"
import { EcommerceProduct } from "./ecommerceProduct"
import { Coordinates } from "./geofencing"
import { ReturnableType } from "./returnableType"

export class CheckoutEcommerceProduct{
    private constructor(
      public product_id: number,
      public description: string,
      public price: EcommerceProductPrice,
      public discount: number,
      public tax: number,
      public quantity: number,
      public sku: string,
      public name: string,
      public returnable_type?: ReturnableType,
      public linked_product_sku?: string,
      public linked_product?: CheckoutEcommerceProduct,
    ) {}
  
    static fromEcommerceProduct(product: EcommerceProduct): CheckoutEcommerceProduct{
      return new CheckoutEcommerceProduct(
        product.product_id,
        product.long_description,
        {
          list_price: product.price,
          final_price: product.price
        },
        product.discount,
        product.tax,
        product.cartCount,
        product.sku,
        product.name,
        product.returnable_type,
        product.linked_product_sku,
        product.linked_product
          ? CheckoutEcommerceProduct.fromEcommerceProduct(product.linked_product)
          : undefined
      )
    }
}

export class CheckoutShippingAddress{
    private constructor(
      public first_Name: string,
      public last_Name: string,
      public country_id: string,
      public region_id: string,
      public region_code: string,
      public region: string,
      public street: string,
      public telephone: string,
      public postal_code: string,
      public city: string,
      public warehouse_code: string,
      public neighborhood: string,
      public municipality: string,
      public references: string,
      public number: string,
      public internal_number: string,
      public route_id: string,
      public encoded_route_info: string,
      public coordinates: Coordinates,
    ) {}
  
    static fromEcommerceAddress(address: EcommerceAddress): CheckoutShippingAddress{
      return new CheckoutShippingAddress(
        (address.name as string).split(' ')[0] || "",
        (address.name as string).split(' ').slice(1).join(' ') || "",
        address.country_id,
        address.region_id,
        address.region_code,
        address.region,
        address.street,
        address.phone as string,
        address.postal_code,
        address.city,
        address.warehouse_code,
        address.neighborhood as string,
        address.municipality,
        address.references as string,
        address.number as string,
        address.internal_number as string,
        address.route_id,
        address.encoded_route_info as string,
        address.coordinates,
      )
    }
}

export class CheckoutBillingAddress{
    private constructor(
      public first_Name: string,
      public last_Name: string,
      public country_id: string,
      public region_id: string,
      public region_code: string,
      public region: string,
      public street: string,
      public telephone: string,
      public postal_code: string,
      public city: string,
      public neighborhood: string,
      public municipality: string,
      public number: string,
      public internal_number: string,
    ) {}
  
    static fromBillingAddress(billingAddress: BillingAddress, shippingAddress: CheckoutShippingAddress): CheckoutBillingAddress{
      // Set the CheckoutBillingAddress using the billingAddress given or the shippingAddress in case it is empty.
      return new CheckoutBillingAddress(
        shippingAddress.first_Name,
        shippingAddress.last_Name,
        shippingAddress.country_id,
        billingAddress?.region_id || shippingAddress.region_id,
        billingAddress?.region_code || shippingAddress.region_code,
        billingAddress?.region || shippingAddress.region,
        billingAddress?.street.join(" ") || shippingAddress.street,
        shippingAddress.telephone,
        billingAddress?.postcode || shippingAddress.postal_code,
        billingAddress?.city || shippingAddress.city,
        billingAddress?.neighborhood || shippingAddress.neighborhood,
        billingAddress?.municipality || shippingAddress.municipality,
        billingAddress?.number || shippingAddress.number,
        billingAddress?.number_int || shippingAddress.internal_number,
      )
    }
}

export class CheckoutBillingInformation{
    private constructor(
      public want_bill: boolean,
      public rfc: string,
      public billing_name: string,
      public rfc_type: string,
      public tax_regime: string,
      public tax_cfdi_use: string,
      public tax_capital_regime: string,
    ) {}
  
    static fromBillingInformation(invoice: BillingInformation): CheckoutBillingInformation{
      return new CheckoutBillingInformation(
        invoice?.want_bill ?? false,
        invoice?.rfc ?? "",
        invoice?.billing_name ?? "",
        invoice?.rfc_type ?? "",
        invoice?.tax_regime ?? "",
        invoice?.tax_cfdi_use ?? "",
        invoice?.tax_capital_regime ?? "",
      )
    }
  }