import { Grid, makeStyles, Typography } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import {
  setAccountInitialState,
  setAccountProvider,
  setConversationContext,
  setRegisterAccount,
} from "features/common/accountSlice";
import {
  /*getCategories,
  getSubcategories,
  getProducts,*/
  funcCatalogueGetById,
  funcCataloguePortafolioGetById,
  funcGetDeliveries,
  funcRegisterGetByPhone,
  funcShoppingCartCreate,
  funcSuggestedOrderCampaignGet,
  funcSuggestedOrderGet,
  getAccountProviderByPhone,
  getConversationContext,
} from "features/common/API";
import {
  setCategories,
  setCategoryInitialState,
  toggleParentCategory,
  updateSelectedSubcategoriesByParent,
} from "features/common/categorySlice";
import { setDeliveryData, setDeliveryDate, setDeliveryInitialState } from "features/common/deliverySlice";
import { setGeofencingInitialState } from "features/common/geofencingSlice";
import {
  GTM_LIST_NAME_DIRECT_CATEGORY,
  GTM_LIST_NAME_DIRECT_PROMOTION,
  GTM_LIST_NAME_EDIT_RE_ORDER,
  GTM_LIST_NAME_EDIT_SUGGESTED_ORDER,
  handleWebviewInizialized,
} from "features/common/gtmEventHandler";
import {
  filterProducts,
  initProducts,
  loadCart,
  setListName,
  setProductsInitialState,
} from "features/common/productSlice";
import { BotWhatsAppLink, GeofencingPageLink, ProductsRelativeLink } from "features/common/urlBuilder";
import { CheckoutShippingAddress } from "models/checkout";
import { ConversationContext } from "models/conversationContext";
import { SuggestedOrder } from "models/suggestedOrder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EcommerceAddress } from "../../models/ecommerceAddress";
import { CategoryList } from "./CategoryList";
import { FullPageLoader, LoaderType } from "./FullPageLoader";
import { MessageDialog } from "./MessageDialog";
// Components

import { PostShoppingCartCreate } from "models/ecommerceCart";
import { EcommerceProduct } from "models/ecommerceProduct";
import moment from "moment";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Banner } from "./Banner";
import { ProductCarousel } from "./ProductCarousel";
import { PromotionCarousel } from "./PromotionCarousel";

const useStyles = makeStyles((theme) => ({
  banner: {
    // TODO: VERIFY THIS IS WHAT WE WANT OR GET MORE SIZES
    width: "100%",
  },
  appbar: {
    position: "fixed",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#fafafa",
  },
  main: {
    marginTop: "155px",
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  ProductGrid: {
    justifyContent: "center",
  },
  label: {
    textAlign: "left",
    position: "relative",
    fontWeight: 700,
    borderBottomRightRadius: "9px",
    borderTopRightRadius: "9px",
    padding: "0px 0px 8px 5px",
    whiteSpace: "nowrap",
    minWidth: "140px",
    display: "inline-block",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  paddingContent: {
    padding: "16px 11px 16px 11px",
  },
}));

export const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();
  const { selectedSubcategories } = useSelector((state: RootState) => state.categories);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);
  const { conversationContext, registerAccount, accountProvider, searchFilter, categoryFilter } = useSelector(
    (state: RootState) => state.account
  );
  let { cartId } = useSelector((state: RootState) => state.products);

  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [carouselProducts, setCarouselProducts] = useState<EcommerceProduct[]>([]);
  const [photoCategories, setPhotoCategories] = useState<EcommerceCategories[]>([]);

  const searchParams = new URLSearchParams(window.location.search);
  const contextId: string = searchParams.get("contextId") || "";
  const edit: string = searchParams.get("edit") || "";
  const editSuggestedOrder: string = searchParams.get("editSuggestedOrder") || "";
  const promo: string = searchParams.get("promo") || "";

  const [isCategoriesLoaded, setIsCategoriesLoaded] = useState(false);
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  let categoriesState = useSelector((state: RootState) => state.categories);
  const [comboCatId, setComboCatId] = useState("");

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  let bannerMessage = accountProvider?.parameters?.find((p) => p.key === "banner_message")?.value;
  const bannerStartDate = accountProvider?.parameters?.find((p) => p.key === "banner_start_date")?.value;
  const bannerEndDate = accountProvider?.parameters?.find((p) => p.key === "banner_end_date")?.value;
  if (!bannerStartDate || !bannerEndDate) {
    bannerMessage = undefined;
  }

  let startDate = new Date();
  if (moment(bannerStartDate, "YYYY-MM-DD HH:mm:ss.SSS").isValid()) {
    startDate = moment(bannerStartDate, "YYYY-MM-DD HH:mm:ss.SSS").toDate();
  }

  let endDate = new Date();
  if (moment(bannerEndDate, "YYYY-MM-DD HH:mm:ss.SSS").isValid()) {
    endDate = moment(bannerEndDate, "YYYY-MM-DD HH:mm:ss.SSS").toDate();
  }
  const curdate = new Date();

  const isInRange = curdate >= startDate && curdate <= endDate;

  useEffect(() => {
    (async () => {
      if (!deliveryData.warehouse_code && edit !== "true" && editSuggestedOrder !== "true") {
        setIsErrorMessageOpen(true);
        setErrorMessage(
          "Lo siento 😅, tu sesión ha expirado, presiona regresar para continuar con el flujo de compra."
        );
        setErrorMessageButtonLink(GeofencingPageLink());
        setErrorMessageButtonText("Regresar");
        return;
      }
      setIsLoaderActive(true);

      var accountProviderId: number = conversationContext?.accountProviderId as number;
      var accountId: string = registerAccount?.account_id as string;
      var warehouseCode: string = deliveryData.warehouse_code;
      var route_id: string = deliveryData.route_id;
      var suggestedOrder: SuggestedOrder | undefined;
      var editOrderListName: string = GTM_LIST_NAME_EDIT_RE_ORDER;

      if (edit === "true" || editSuggestedOrder === "true") {
        // Set the initalState of all the slices to be clean, as this is the first step on the webview for the users using the edit Flows.
        dispatch(setAccountInitialState());
        dispatch(setDeliveryInitialState());
        dispatch(setProductsInitialState());
        dispatch(setCategoryInitialState());
        dispatch(setGeofencingInitialState());

        editOrderListName = edit === "true" ? GTM_LIST_NAME_EDIT_RE_ORDER : GTM_LIST_NAME_EDIT_SUGGESTED_ORDER;

        let bottlers: Bottler[] = [];
        let country_id: string = "";

        const conversationContext = await getConversationContext(contextId || "");
        const accountProvider = await getAccountProviderByPhone(conversationContext?.whatsappNumber!);

        if (ConversationContext.isValid(conversationContext, accountProvider)) {
          dispatch(setConversationContext(conversationContext!));
          dispatch(setAccountProvider(accountProvider!));

          bottlers = JSON.parse(accountProvider?.bottlers ?? "[]");
          accountProviderId = conversationContext?.accountProviderId!;
          country_id = accountProvider?.country_id as string;

          const registerAccount = (await funcRegisterGetByPhone(
            conversationContext?.accountProviderId!,
            conversationContext?.cellPhoneNumber!
          )) as RegisterAccount;
          accountId = registerAccount?.account_id;
          dispatch(setRegisterAccount(registerAccount));

          suggestedOrder =
            edit === "true"
              ? await funcSuggestedOrderGet(accountProviderId, accountId, true)
              : await funcSuggestedOrderCampaignGet(accountProviderId, accountId);

          if (!suggestedOrder) {
            setIsErrorMessageOpen(true);
            setErrorMessage(
              "Lo siento 😅, tu sesión de editar el pedido ha expirado. Presiona regresar para volver a Whatsapp."
            );
            setErrorMessageButtonLink(BotWhatsAppLink(conversationContext?.whatsappNumber));
            setErrorMessageButtonText("Regresar");
            return;
          }

          warehouseCode = suggestedOrder?.shippingAddress?.warehouse_code as string;
          route_id = suggestedOrder?.shippingAddress?.route_id as string;

          const deliveryAddress = EcommerceAddress.fromCheckoutShippingAddress(
            suggestedOrder?.shippingAddress as CheckoutShippingAddress
          );

          // Add the bottlerName to the DeliveryAddress using the bottlers list on the AccountProvider
          const defaultBottler = bottlers.find((bottler) => bottler.warehouse_code === "*");

          const bottlerName: string = (bottlers.find(
            (bottler) => bottler.warehouse_code === deliveryAddress.warehouse_code
          )?.bottler_name || defaultBottler?.bottler_name) as string;

          handleWebviewInizialized(contextId || "", bottlerName, country_id);

          deliveryAddress.bottler = bottlerName;

          dispatch(setDeliveryData(deliveryAddress));
        } else {
          setIsErrorMessageOpen(true);
          setErrorMessage("Lo siento 😅, el link de compra ha expirado. Presiona Regresar para volver a Whatsapp");
          setErrorMessageButtonLink(BotWhatsAppLink(conversationContext?.whatsappNumber as string));
          setErrorMessageButtonText("Regresar");
          return;
        }
      }

      // TODO Get registerAccount?.account_id
      const Categories: EcommerceCategories[] = (await funcCatalogueGetById(
        accountProviderId,
        accountId,
        warehouseCode
      )) as EcommerceCategories[];

      if (!Categories) {
        setIsErrorMessageOpen(true);
        setErrorMessage(
          "Lo siento 😅, tu sesión ha expirado, presiona regresar e introduce una nueva dirección en formato: calle y número de puerta."
        );
        setErrorMessageButtonLink(GeofencingPageLink());
        setErrorMessageButtonText("Regresar");
        return;
      }

      const bottlers: Bottler[] = JSON.parse(accountProvider?.bottlers ?? "[]");
      const comboCategoryId = bottlers.find((bottler) => bottler.warehouse_code === warehouseCode)?.combo_category_id;
      const comboSubcategoryId = "999999999999999";
      const discountSubcategoryId = "9999999999999998";
      const comboName = bottlers.find((bottler) => bottler.warehouse_code === warehouseCode)?.combo_name;
      const categoriesOrder = bottlers.find((bottler) => bottler.warehouse_code === warehouseCode)?.categories_order;

      setComboCatId(comboCategoryId!);

      var categories = Categories?.filter((category) => category.parent_id === "0");
      const subcategories = Categories?.filter((category) => category.parent_id !== "0");

      // Dont use categories with no child subcategories.
      categories = categories.filter(
        (category) => subcategories.filter((sub) => sub.parent_id === category.child_id).length > 0
      );

      // Sorting categories
      if (categoriesOrder !== undefined) {
        var sortedCategories: EcommerceCategories[] = [];
        var unsortedCategories: EcommerceCategories[] = [];
        categoriesOrder.forEach((e) => {
          var match = categories.find((x) => x.child_id === e.toString());
          if (match !== null && match !== undefined) {
            sortedCategories.push(match);
          }
        });

        // Adding unsorted categories if not especified on the config array.
        categories.forEach((c) => {
          var exist = sortedCategories.find((x) => x.child_id === c.child_id);
          if (exist === null || exist === undefined) {
            unsortedCategories.push(c);
          }
        });

        categories = sortedCategories.concat(unsortedCategories);
      }

      const comboCategoryIndex = categories?.findIndex((category) => category.child_id === comboCategoryId);

      if (comboCategoryIndex !== -1) {
        const comboCategory = categories.splice(comboCategoryIndex, 1);
        categories.unshift(comboCategory[0]);
      } else if (comboCategoryId) {
        // Create artifitial promo category
        const comboCategory: EcommerceCategories = {
          account_provider_id: accountProviderId,
          account_id: accountId,
          parent_id: "0",
          group: comboName as string,
          child_id: comboCategoryId,
          order: 0,
          description: comboName as string,
          image_url: "",
          is_alcoholic: false,
          non_alcoholic_description: comboName as string,
        };

        const comboSubCategory: EcommerceCategories[] = [
          {
            account_provider_id: accountProviderId,
            account_id: accountId,
            parent_id: comboCategoryId,
            group: "Descuentos", // comboName as string,
            child_id: discountSubcategoryId,
            order: 0,
            description: "Descuentos", // comboName as string,
            image_url: "",
            is_alcoholic: false,
            non_alcoholic_description: "Descuentos", // comboName as string,
          },
          {
            account_provider_id: accountProviderId,
            account_id: accountId,
            parent_id: comboCategoryId,
            group: "Combos", // comboName as string,
            child_id: comboSubcategoryId,
            order: 0,
            description: "Combos", // comboName as string,
            image_url: "",
            is_alcoholic: false,
            non_alcoholic_description: "Combos", // comboName as string,
          },
        ];

        categories.unshift(comboCategory);
        subcategories.unshift(...comboSubCategory);
      }

      // Set Categories on slice
      dispatch(setCategories({ categories, subcategories }));

      setPhotoCategories(categories);

      // Fetch and set deliveryDate data
      const deliveryDates: EcommereceDeliveryDate[] | undefined = await funcGetDeliveries(
        accountProviderId,
        accountId,
        warehouseCode,
        route_id
      );
      if (deliveryDates) dispatch(setDeliveryDate(deliveryDates[0]));

      let Products: EcommerceProduct[] = (await funcCataloguePortafolioGetById(
        accountProviderId,
        accountId,
        warehouseCode
      )) as EcommerceProduct[];

      // Add artifitial promo category to corresponding products
      if (comboCategoryIndex === -1 && comboCategoryId) {
        Products.forEach((element: EcommerceProduct) => {
          if (
            element.has_promotion === true ||
            element.discount > 0 ||
            element.shortname?.toLowerCase().includes("paquete")
          ) {
            if (element.discount > 0) {
              element.categories?.unshift(discountSubcategoryId);
            }

            if (element.name?.toLowerCase().includes("paquete")) {
              element.categories?.unshift(comboSubcategoryId);
            }

            element.categories?.unshift(comboCategoryId);
            element.category = comboCategoryId;
          }
        });
      }
      // Set Products on slice
      if (Products)
        dispatch(
          initProducts({ products: Products, selectedCategories: selectedSubcategories, categories: Categories })
        );

      // Finish primary loading before setCarouselProducts
      if (promo !== "true" && searchFilter !== undefined && searchFilter.length === 0) {
        setIsLoaderActive(false);
      }

      setIsAllLoaded(true);

      if (suggestedOrder) {
        // On Edit Order flow load the suggested Products on the webview cart.
        var cartProducts: EcommerceProduct[] = Products.filter((product) =>
          suggestedOrder?.products.find(
            (suggestedProduct) => suggestedProduct.product_id === product.product_id.toString()
          )
        ).map((product) => {
          var cartCount: number =
            suggestedOrder?.products.find(
              (suggestedProduct) => suggestedProduct.product_id === product.product_id.toString()
            )?.quantity || 1;
          return { ...product, cartCount };
        });

        // Create cart if it doesn't exist
        if (!cartId) {
          var payload = {
            outlet: deliveryData?.encoded_route_info, // equal to encoded_route_info
            store: deliveryData?.bottler, // equal to bottler from deliveryData
            products: [], // equal to products
          } as PostShoppingCartCreate;

          const shoppingCart = await funcShoppingCartCreate(
            conversationContext?.accountProviderId as number,
            registerAccount?.account_id as string,
            payload
          );

          cartId = shoppingCart?.supplier_cart_id ?? cartId;
        }

        if (cartProducts) {
          dispatch(
            loadCart({
              products: cartProducts,
              position: editOrderListName,
              countryId: accountProvider?.country_id,
              currency: accountProvider?.currency,
              cartId: cartId,
            })
          );
        }
      }

      // Show in the carrousel the products that have disccounts
      var productsWithDisccountFilter = (product: EcommerceProduct) => product.discount > 0;

      // Show in the carrousel the suggested products if available
      if (!suggestedOrder) suggestedOrder = await funcSuggestedOrderGet(accountProviderId, accountId, false);

      var suggestedProductsFilter = (product: EcommerceProduct) =>
        suggestedOrder?.products?.find((suggestedProduct) =>
          EcommerceProduct.filterBySuggestedProduct(product, suggestedProduct)
        );

      const productsOnCarrousel: EcommerceProduct[] = Products.filter(
        (product) => productsWithDisccountFilter(product) || suggestedProductsFilter(product)
      ).reverse();

      setCarouselProducts(productsOnCarrousel);
    })();
  }, []);

  useEffect(() => {
    if (isAllLoaded && searchFilter !== undefined && searchFilter.length > 0) {
      navigate(ProductsRelativeLink());
    }
    // Load promotions category if "promo" urlParam exists
    if (isAllLoaded && !isCategoriesLoaded && promo === "true") {
      goToPromotions(photoCategories);
    }
    // Load category if "ctg" urlParam exists
    if (isAllLoaded && !isCategoriesLoaded && categoryFilter !== "") {
      goToCategory(photoCategories);
    }
  }, [isAllLoaded]);

  const goToPromotions = async (categories: EcommerceCategories[]) => {
    // Avoid load promotions category more than once
    setIsCategoriesLoaded(true);

    var category = categories.find((x) => x.child_id === comboCatId);

    if (category !== undefined) {
      await dispatch(
        toggleParentCategory({
          name: category.group,
          countryId: accountProvider?.country_id,
          currency: accountProvider?.currency,
        })
      );
      const updatedSubcategories: number[] = updateSelectedSubcategoriesByParent(categoriesState, category.group);
      await dispatch(setListName({ listName: GTM_LIST_NAME_DIRECT_PROMOTION }));
      await dispatch(
        filterProducts((product) => {
          return updatedSubcategories.some((cat) => EcommerceProduct.filterByCategory(product, cat));
        })
      );
      setIsCategoriesLoaded(false);
      if (window.location.pathname !== "/products") {
        navigate({
          pathname: "/products",
          search: `?${createSearchParams(searchParams)}`,
        });
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setIsLoaderActive(false);
      setIsErrorMessageOpen(true);
      setErrorMessage("Lo sentimos 😔, no hay promociones disponibles por el momento.");
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Cerrar");
    }
  };
  const goToCategory = async (categories: EcommerceCategories[]) => {
    // Avoid load promotions category more than once
    setIsCategoriesLoaded(true);
    var category = categories.find((x) => x.description === categoryFilter);

    if (category !== undefined) {
      await dispatch(
        toggleParentCategory({
          name: category.group,
          countryId: accountProvider?.country_id,
          currency: accountProvider?.currency,
        })
      );
      const updatedSubcategories: number[] = updateSelectedSubcategoriesByParent(categoriesState, category.group);
      await dispatch(setListName({ listName: `${GTM_LIST_NAME_DIRECT_CATEGORY} - ${category.group}` }));
      await dispatch(
        filterProducts((product) => {
          return updatedSubcategories.some((cat) => EcommerceProduct.filterByCategory(product, cat));
        })
      );
      setIsCategoriesLoaded(false);
      if (window.location.pathname !== "/products") {
        navigate({
          pathname: "/products",
          search: `?${createSearchParams(searchParams)}`,
        });
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setIsLoaderActive(false);
      setIsErrorMessageOpen(true);
      setErrorMessage("Lo sentimos 😔, la categoría no esta disponible por el momento.");
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Cerrar");
    }
  };

  var loadingMessage: string =
    edit === "true" || editSuggestedOrder === "true"
      ? "¡Estamos cargando tu carrito! Por favor espera un momento"
      : promo === "true"
      ? "Cargando promociones..."
      : "Cargando productos...";

  return (
    <>
      <main className={classes.main}>
        {isInRange && bannerMessage ? <Banner showCloseIcon type="warning" message={bannerMessage} /> : null}
        <PromotionCarousel />
        <CategoryList categories={photoCategories} show={6} />
        {carouselProducts && carouselProducts.length !== 0 && (
          <Grid className={classes.paddingContent}>
            <Typography variant="h2" className={classes.label}>
              Productos Recomendados
            </Typography>
            <ProductCarousel products={carouselProducts} />
          </Grid>
        )}
        {isLoaderActive ? <FullPageLoader type={LoaderType.Authentication} text={loadingMessage} /> : <></>}
        {isErrorMessageOpen ? (
          <MessageDialog
            message={errorMessage}
            link={errorMessageButtonLink}
            text={errorMessageButtonText}
            setIsDialogActive={setIsErrorMessageOpen}
          />
        ) : null}
      </main>
    </>
  );
};
