import { Container, CssBaseline, Grid } from '@material-ui/core';
import CDSCreateUser from 'features/main/CreateUser';
import { Navbar } from 'features/main/Navbar';
import React from 'react';

export const CreateUserPage = ({config}: {config: CDSIdConfig}) => {
	return (
		<>
			<CssBaseline/>
			<Navbar/>
			<main>
				<Container>
					<Grid>
						<CDSCreateUser config = {config}></CDSCreateUser> 
					</Grid>
				</Container>
			</main>
		</>
	);
};

