import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EcommerceProduct } from 'models/ecommerceProduct';

import {
  GTM_LIST_NAME_DEFAULT,
  handleAddToCartButtonClick,
  handleCheckoutButtonClick,
  handleProductClick,
  handleRemoveAllFromCartButtonClick,
  handleRemoveFromCartButtonClick,
} from './gtmEventHandler';
export interface ProductsState {
  products: EcommerceProduct[];
  selectedProducts: EcommerceProduct[];
  cart: { [productId: string]: EcommerceProduct };
  cartId: string;
  subtotal: number;
  shipping: number;
  cartProductsCount: number;
  listName: string;
}

const initialState: ProductsState = {
  products: [],
  selectedProducts: [],
  cart: {},
  cartId: '',
  subtotal: 0,
  shipping: 0,
  cartProductsCount: 0,
  listName: GTM_LIST_NAME_DEFAULT,
};

const products = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsInitialState: (state: ProductsState, action: PayloadAction<void>) => {
      return initialState;
    },
    initProducts: (
      state: ProductsState,
      action: PayloadAction<{
        products: EcommerceProduct[];
        selectedCategories: number[];
        categories: EcommerceCategories[];
      }>
    ) => {
      // Compare persisted data with new data
      //if (state.products.length !== 0 && state.products.every((val, i) => val === action.payload[i])) return;

      const initProductState = (product: EcommerceProduct) => {
        product.discountPercent = Math.round(
          (EcommerceProduct.totalDiscount(product) /
            (EcommerceProduct.totalPrice(product) + EcommerceProduct.totalDiscount(product))) *
            100
        );
        product.shortname = product.name.replace(product.packaging, '').replace(product.net_content, '');
        product.category_name =
          action.payload.categories.find((category) => category.child_id === product.category)?.group ??
          product.category;
      };

      state.products = action.payload.products;
      state.products.forEach((product) => {
        initProductState(product);
        if (product.linked_product) {
          initProductState(product.linked_product);
        }
      });

      // Init the selectedProducts;
      const productsToEnd: EcommerceProduct[] = [];
      const productList = state.products.filter((product) => {
        if (action.payload.selectedCategories.indexOf(Number(product.category)) === -1) {
          return false;
        }

        if ((product.oos !== undefined && product.oos === '') || product.price <= 0 || !product.sku) {
          productsToEnd.push(product);
          return false;
        }

        return true;
      });

      productList.push(...productsToEnd);
      state.selectedProducts = productList;
    },
    clearCart: (state: ProductsState, action: PayloadAction<CheckoutResult | undefined>) => {
      if (action.payload) {
        handleCheckoutButtonClick(
          action.payload.products,
          action.payload.transactionId,
          state,
          action.payload.countryId
        );
      }

      state.cart = initialState.cart;
      state.cartId = initialState.cartId;
      state.shipping = initialState.shipping;
      state.subtotal = initialState.subtotal;
      state.cartProductsCount = initialState.cartProductsCount;
    },
    addToCart: (
      state: ProductsState,
      action: PayloadAction<{
        product: EcommerceProduct;
        position?: string;
        viewPosition: number;
        countryId?: string;
        currency?: string;
        sendClick?: boolean;
        cartId?: string;
      }>
    ) => {
      const product: EcommerceProduct = { ...action.payload.product };
      state.cartId = action.payload.cartId ?? state.cartId;

      if (state.cart[product.product_id] === undefined) {
        product.cartCount = 1;
        product.view_position = action.payload.viewPosition;
        product.list_name = action.payload.position;
        state.cart[product.product_id] = product;
        const temp = (state.subtotal + EcommerceProduct.totalPrice(product)).toFixed(2);
        state.subtotal = parseFloat(temp);
        state.cartProductsCount += EcommerceProduct.productCount(product);
      } else {
        if (state.cart[product.product_id].cartCount < product.stock) {
          state.cart[product.product_id].cartCount++;
          const temp = (state.subtotal + EcommerceProduct.totalPrice(product)).toFixed(2);
          state.subtotal = parseFloat(temp);
          state.cartProductsCount += EcommerceProduct.productCount(product);
        }
      }
      handleAddToCartButtonClick(
        action.payload.product,
        state,
        action.payload.position ?? state.listName,
        action.payload.viewPosition,
        action.payload.countryId,
        action.payload.currency
      );
      if (action.payload.sendClick) {
        handleProductClick(
          action.payload.product,
          action.payload.position ?? state.listName,
          action.payload.viewPosition,
          action.payload.countryId,
          action.payload.currency
        );
      }
    },
    removeProduct: (
      state: ProductsState,
      action: PayloadAction<{
        product: EcommerceProduct;
        position?: string;
        viewPosition: number;
        countryId?: string;
        currency?: string;
        sendClick?: boolean;
      }>
    ) => {
      const product: EcommerceProduct = { ...action.payload.product };
      if (!state.cart[product.product_id]) return;

      state.cart[product.product_id].cartCount--;
      const temp = (state.subtotal - EcommerceProduct.totalPrice(product)).toFixed(2);
      state.subtotal = parseFloat(temp);
      if (state.cart[product.product_id].cartCount === 0) {
        delete state.cart[product.product_id];
      }
      state.cartProductsCount -= EcommerceProduct.productCount(product);
      handleRemoveFromCartButtonClick(
        action.payload.product,
        state,
        action.payload.position ?? state.listName,
        action.payload.viewPosition,
        action.payload.countryId,
        action.payload.currency
      );
      if (action.payload.sendClick) {
        handleProductClick(
          action.payload.product,
          action.payload.position ?? state.listName,
          action.payload.viewPosition,
          action.payload.countryId,
          action.payload.currency
        );
      }
    },
    removeAllProducts: (
      state: ProductsState,
      action: PayloadAction<{
        product: EcommerceProduct;
        position: string;
        viewPosition: number;
        countryId?: string;
        currency?: string;
        sendClick?: boolean;
      }>
    ) => {
      const product: EcommerceProduct = { ...action.payload.product };
      if (!state.cart[product.product_id]) return;

      const cartCount = state.cart[product.product_id].cartCount;
      state.cart[product.product_id].cartCount = 0;
      const temp = (state.subtotal - EcommerceProduct.totalPrice(product) * cartCount).toFixed(2);
      state.subtotal = parseFloat(temp);
      if (state.cart[product.product_id].cartCount === 0) {
        delete state.cart[product.product_id];
      }
      state.cartProductsCount -= cartCount * EcommerceProduct.productCount(product);
      handleRemoveAllFromCartButtonClick(
        action.payload.product,
        state,
        action.payload.position,
        action.payload.viewPosition,
        action.payload.countryId,
        action.payload.currency
      );
      if (action.payload.sendClick) {
        handleProductClick(
          action.payload.product,
          action.payload.position ?? state.listName,
          action.payload.viewPosition,
          action.payload.countryId,
          action.payload.currency
        );
      }
    },
    loadCart: (
      state: ProductsState,
      action: PayloadAction<{ products: EcommerceProduct[]; position: string; countryId?: string; currency?: string, cartId?: string }>
    ) => {
      if (Object.keys(state.cart).length === 0) {
        state.cartId = action.payload.cartId ?? state.cartId;
        action.payload.products
          .filter((product) => EcommerceProduct.isValidForCatalogue(product))
          .forEach((cartProduct, index) => {
            const cartProductCount = cartProduct.cartCount ?? 1;
            state.cartProductsCount += cartProductCount * EcommerceProduct.productCount(cartProduct);
            if (state.cart[cartProduct.product_id] === undefined) {
              const product = { ...cartProduct };
              product.cartCount = cartProductCount;
              state.cart[cartProduct.product_id] = product;
              const temp = (state.subtotal + EcommerceProduct.totalPrice(cartProduct) * cartProductCount).toFixed(2);
              state.subtotal = parseFloat(temp);
              handleAddToCartButtonClick(
                state.cart[cartProduct.product_id],
                state,
                action.payload.position,
                index + 1,
                action.payload.countryId,
                action.payload.currency
              );
            } else {
              if (state.cart[cartProduct.product_id].cartCount + cartProductCount <= cartProduct.stock) {
                state.cart[cartProduct.product_id].cartCount =
                  state.cart[cartProduct.product_id].cartCount + cartProductCount;
                const temp = (state.subtotal + EcommerceProduct.totalPrice(cartProduct) * cartProductCount).toFixed(2);
                state.subtotal = parseFloat(temp);
                handleAddToCartButtonClick(
                  state.cart[cartProduct.product_id],
                  state,
                  action.payload.position,
                  index + 1,
                  action.payload.countryId,
                  action.payload.currency
                );
              }
            }
          });
      }
    },
    filterProducts: (state: ProductsState, action: PayloadAction<(prod: EcommerceProduct) => boolean>) => {
      const productsToEnd: EcommerceProduct[] = [];
      let productList = state.products.filter(action.payload);
      productList = productList.filter((product) => {
        if ((product.oos !== undefined && product.oos === '') || product.price <= 0 || !product.sku) {
          productsToEnd.push(product);
          return false;
        }

        return true;
      });

      productList.push(...productsToEnd);
      state.selectedProducts = productList;
    },
    setCartProductCount: (state: ProductsState, action: PayloadAction<undefined>) => {
      state.cartProductsCount = 0;
      const productIds: string[] = Object.keys(state.cart);
      productIds.forEach((productId) => (state.cartProductsCount += state.cart[productId].cartCount));
    },
    setListName: (state: ProductsState, action: PayloadAction<{ listName: string }>) => {
      state.listName = action.payload.listName;
    },
  },
});

export const {
  setProductsInitialState,
  initProducts,
  addToCart,
  removeProduct,
  removeAllProducts,
  clearCart,
  loadCart,
  filterProducts,
  setCartProductCount,
  setListName,
} = products.actions;

export const productsReducer = products.reducer;
