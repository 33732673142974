import { ReturnableType, ReturnableTypes } from "./returnableType";
import { SuggestedOrderProduct } from "./suggestedOrder";
import { stringUtils } from "./stringUtils";

export class EcommerceProduct {
  public constructor(
    public account_provider_id: number,
    public account_id: string,
    public product_id: number,
    public price: number,
    public category: string,
    public packaging: string,
    public net_content: string,
    public name: string,
    public image_url: string,
    public refillable: boolean,
    public short_description: string,
    public long_description: string,
    public non_alcoholic_name: string,
    public availability: boolean,
    public discount: number,
    public has_promotion: boolean,
    public stock: number,
    public tax: number,
    public sku: string,
    public categories: string[],
    public cartCount: number,
    public shortname: string,
    public discountPercent: number,
    public is_alcoholic?: boolean,
    public returnable_type?: ReturnableType,
    public linked_product_sku?: string,
    public linked_product?: EcommerceProduct,
    public oos?:string,

    public category_name?: string,
    public view_position?: number,
    public list_name?: string,
  ) { }

  static isValidForCatalogue(product: EcommerceProduct): boolean {
    return product.returnable_type !== ReturnableTypes.Container && (product.returnable_type !== ReturnableTypes.StarterPack || product.linked_product !== undefined);
  }

  static filterByCategory(product: EcommerceProduct, category: number): boolean {
    return this.isValidForCatalogue(product) && product.categories.includes(category.toString());
  }

  static filterByName(product: EcommerceProduct, text: string): boolean {
    const productNameNormalized = stringUtils.NormalizeString(product.name);
    const textNormalized = stringUtils.NormalizeString(text);
    const discountLabelNormalized = stringUtils.NormalizeString(this.discountLabel(product));

    return this.isValidForCatalogue(product) && (productNameNormalized.includes(textNormalized) || discountLabelNormalized.includes(textNormalized));
  }

  static filterBySuggestedProduct(product: EcommerceProduct, suggestedProduct: SuggestedOrderProduct): boolean {
    return this.isValidForCatalogue(product) && product.product_id.toString() === suggestedProduct.product_id
  }

  static totalPrice(product: EcommerceProduct): number {
    return product.price + (product.linked_product?.price ?? 0);
  }

  static totalDiscount(product: EcommerceProduct): number {
    return product.discount + (product.linked_product?.discount ?? 0);
  }

  static productCount(product: EcommerceProduct): number {
    return product.returnable_type === ReturnableTypes.StarterPack? 2 : 1;
  }

  static discountLabel(product: EcommerceProduct): string {
    return product.discountPercent > 0 ? product.discountPercent + "% Menos!": "";
  }
}