import axios, { AxiosError } from 'axios';
import {
  CheckoutBillingAddress,
  CheckoutBillingInformation,
  CheckoutEcommerceProduct,
  CheckoutShippingAddress,
} from 'models/checkout';
import { ConversationContext } from 'models/conversationContext';
import { EcommerceAddress } from 'models/ecommerceAddress';
import { PostShoppingCartCreate, ShoppingCart } from 'models/ecommerceCart';
import { EcommerceProduct } from 'models/ecommerceProduct';
import { AvailabilityZone, ValidateAvailabilityResult } from 'models/geofencing';
import { SuggestedOrder } from 'models/suggestedOrder';

// Framework Service
const FRAMEWORK_BASE_URL = process.env.REACT_APP_FRAMEWORK_NODE_SERVICES_BASE_URL ?? '';
const FRAMEWORK_KEY = process.env.REACT_APP_FRAMEWORK_SERVICES_X_FUNCTION_KEY ?? '';

const FRAMEWORK_GET_CONVERSATION_CONTEXT = process.env.REACT_APP_FRAMEWORK_GET_CONVERSATION_CONTEXT ?? '';
const FRAMEWORK_POST_FINISH_FLOW = process.env.REACT_APP_FRAMEWORK_POST_FINISH_FLOW ?? '';
const FRAMEWORK_GET_ACCOUNT_PROVIDER_BY_PHONE = process.env.REACT_APP_FRAMEWORK_GET_ACCOUNT_PROVIDER_BY_PHONE ?? '';
const FRAMEWORK_GET_REGISTER_BY_PHONE = process.env.REACT_APP_FRAMEWORK_GET_REGISTER_BY_PHONE ?? '';

const FRAMEWORK_POST_AVAILABILITY_ZONE = process.env.REACT_APP_FRAMEWORK_POST_AVAILABILITY_ZONE ?? '';
const FRAMEWORK_POST_VALIDATE_AVAILABILITY_ZONE = process.env.REACT_APP_FRAMEWORK_POST_VALIDATE_AVAILABILITY_ZONE ?? '';
const FRAMEWORK_GET_ACCOUNT_ADDRESSES = process.env.REACT_APP_FRAMEWORK_GET_ACCOUNT_ADDRESSES ?? '';
const FRAMEWORK_GET_DELIVERY_DATES = process.env.REACT_APP_FRAMEWORK_GET_DELIVERY_DATES ?? '';

const FRAMEWORK_GET_CATEGORIES_CATALOG = process.env.REACT_APP_FRAMEWORK_GET_CATEGORIES_CATALOG ?? '';
const FRAMEWORK_GET_PRODUCT_CATALOG = process.env.REACT_APP_FRAMEWORK_GET_PRODUCT_CATALOG ?? '';
const FRAMEWORK_POST_SHOPPING_CART_CREATE = process.env.REACT_APP_FRAMEWORK_POST_SHOPPING_CART_CREATE ?? '';

const FRAMEWORK_POST_CDS_CUSTOMER = process.env.REACT_APP_FRAMEWORK_POST_CDS_CUSTOMER ?? '';
const FRAMEWORK_POST_B2C_CUSTOMER = process.env.REACT_APP_FRAMEWORK_POST_B2C_CUSTOMER ?? '';
const FRAMEWORK_POST_SAVE_ADDRESS = process.env.REACT_APP_FRAMEWORK_POST_SAVE_ADDRESS ?? '';
const FRAMEWORK_POST_PAYMENT_TOKENIZE = process.env.REACT_APP_FRAMEWORK_POST_PAYMENT_TOKENIZE ?? '';
const FRAMEWORK_POST_PAYMENT_VERIFY = process.env.REACT_APP_FRAMEWORK_POST_PAYMENT_VERIFY ?? '';

const FRAMEWORK_POST_CHECKOUT_PROCESS = process.env.REACT_APP_FRAMEWORK_POST_CHECKOUT_PROCESS ?? '';
const FRAMEWORK_GET_SETTLEMENTS = process.env.REACT_APP_FRAMEWORK_GET_SETTLEMENTS ?? '';
const FRAMEWORK_GET_SUGGESTED_ORDER = process.env.REACT_APP_FRAMEWORK_GET_SUGGESTED_ORDER ?? '';
const FRAMEWORK_GET_SUGGESTED_ORDER_CAMPAIGN = process.env.REACT_APP_FRAMEWORK_GET_SUGGESTED_ORDER_CAMPAIGN ?? '';
const FRAMEWORK_POST_NOTIFICATION = process.env.REACT_APP_FRAMEWORK_POST_NOTIFICATION ?? '';

const framework_services_axios = axios.create({
  baseURL: FRAMEWORK_BASE_URL,
});

framework_services_axios.interceptors.request.use(
  (config) => {
    config.headers = {
      'Content-Type': 'application/json',
      'x-functions-key': `${FRAMEWORK_KEY}`,
    };
    return config;
  },
  (err) => console.log(err)
);

framework_services_axios.interceptors.response.use(
  (response) => response.data
  //(err) => {
  //  console.log(err);
  //}
);

export const getConversationContext = async (contextId: string): Promise<ConversationContext | undefined> => {
  var response = await framework_services_axios
    .get(FRAMEWORK_GET_CONVERSATION_CONTEXT.replace('{contextId}', contextId))
    .catch(function (error) {
      return undefined;
    });

  return response === undefined ? undefined : (response as any as ConversationContext);
};

export const getAccountProviderByPhone = async (phone: string): Promise<AccountProvider | undefined> => {
  var response = await framework_services_axios
    .get(FRAMEWORK_GET_ACCOUNT_PROVIDER_BY_PHONE.replace('{phone}', phone))
    .catch(function (error) {
      return undefined;
    });

  if (response === undefined) return undefined;

  var result = response as any as AccountProvider;

  // Fill additional fields to the accountProvider saved on the parameters list.
  result.registrationFlow = result.parameters?.find((parameter) => parameter.key === 'registrationFlow')?.value;
  result.link_expiration_hours = Number(
    result.parameters?.find((parameter) => parameter.key === 'link_expiration_hours')?.value
  );
  result.link_expiration_template_name = result.parameters?.find(
    (parameter) => parameter.key === 'link_expiration_template_name'
  )?.value;
  result.invalid_address_template_name = result.parameters?.find(
    (parameter) => parameter.key === 'invalid_address_template_name'
  )?.value;
  result.is_using_login_on_geofencing = result.parameters?.find(
    (parameter) => parameter.key === 'is_using_login_on_geofencing'
  )?.value === 'true';
  return result;
};

export const funcAccountAddressGetById = async (
  accountProviderId: number,
  accountId: string
): Promise<Address[] | undefined> => {
  var response = await framework_services_axios
    .get(
      FRAMEWORK_GET_ACCOUNT_ADDRESSES.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      )
    )
    .catch(function (error) {
      console.log('funcAccountAddressGetById error = ', error);
      return undefined;
    });

  var result = response === undefined ? undefined : (response as any as Address[]);
  result = result?.map((address) => {
    return { ...address, number: address.number ? address.number : 'undefined' };
  });
  return result;
};

export const funcProviderPostAvailabilityZone = async (
  accountProviderId: number,
  country_id: string,
  availabilityZone: AvailabilityZone
): Promise<AvailabilityZone | undefined> => {
  var requestResponse = await framework_services_axios
    .post(FRAMEWORK_POST_AVAILABILITY_ZONE.replace('{accountProvider}', accountProviderId.toString()), availabilityZone)
    .catch(function (error) {
      console.log('postAvailability error = ', error);
      return undefined;
    });

  if (requestResponse === undefined) return requestResponse;

  var result = requestResponse as any as AvailabilityZone;
  if (country_id == 'GT') {
    result.region_id = result.region_id ? result.region_id : '0';
    result.region_code = result.region_code ? result.region_code : result.address.region;
  }

  // Set the values of address to the ones on the original availabilityZone that are more reliable.
  result.address = availabilityZone.address;

  return result;
};

export const funcProviderPostValidateAvailabilityZone = async (
  accountProviderId: number,
  route_id: string
): Promise<ValidateAvailabilityResult | undefined> => {
  var response = await framework_services_axios
    .post(FRAMEWORK_POST_VALIDATE_AVAILABILITY_ZONE.replace('{accountProvider}', accountProviderId.toString()), {
      route_id,
    })
    .catch(function (error) {
      console.log('postValidateAvailability error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as ValidateAvailabilityResult);
};

export const funcRegisterGetByPhone = async (
  accountProviderId: number,
  phone: string
): Promise<RegisterAccount | undefined> => {
  var response = await framework_services_axios
    .get(
      FRAMEWORK_GET_REGISTER_BY_PHONE.replace('{accountProvider}', accountProviderId?.toString()).replace(
        '{phoneNumber}',
        phone
      )
    )
    .catch(function (error) {
      console.log('funcRegisterGetByPhone error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as RegisterAccount);
};

export const funcGetDeliveries = async (
  accountProviderId: number,
  accountId: string,
  warehouse_code: string,
  route_id: string,
): Promise<EcommereceDeliveryDate[] | undefined> => {
  var response = await framework_services_axios
    .get(
      FRAMEWORK_GET_DELIVERY_DATES.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ) +
        '?warehouse_code=' +
        warehouse_code
        +
        '&route_id=' +
        route_id
    )
    .catch(function (error) {
      console.log('funcGetDeliveries error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as EcommereceDeliveryDate[]);
};

export const funcCatalogueGetById = async (
  accountProviderId: number,
  accountId: string,
  warehouse_code: string
): Promise<EcommerceCategories[] | undefined> => {
  var response = await framework_services_axios
    .get(
      FRAMEWORK_GET_CATEGORIES_CATALOG.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ) +
        '?warehouse_code=' +
        warehouse_code
    )
    .catch(function (error) {
      console.log('funcCatalogueGetById error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as EcommerceCategories[]);
};

export const funcCataloguePortafolioGetById = async (
  accountProviderId: number,
  accountId: string,
  warehouse_code: string
): Promise<EcommerceProduct[] | undefined> => {
  var response = await framework_services_axios
    .get(
      FRAMEWORK_GET_PRODUCT_CATALOG.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ) +
        '?warehouse_code=' +
        warehouse_code
    )
    .catch(function (error) {
      console.log('funcCatalogueGetById error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as EcommerceProduct[]);
};

export const funcSuggestedOrderGet = async (
  accountProviderId: number,
  accountId: string,
  isReOrder: boolean
): Promise<SuggestedOrder | undefined> => {
  var response = await framework_services_axios
    .get(
      FRAMEWORK_GET_SUGGESTED_ORDER.replace('{accountProvider}', accountProviderId.toString())
        .replace('{accountId}', accountId)
        .replace('{isReOrder}', isReOrder.toString())
    )
    .catch(function (error) {
      console.log('funcSuggestedOrderGet error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as SuggestedOrder);
};

export const funcSuggestedOrderCampaignGet = async (
  accountProviderId: number,
  accountId: string
): Promise<SuggestedOrder | undefined> => {
  var response = await framework_services_axios
    .get(
      FRAMEWORK_GET_SUGGESTED_ORDER_CAMPAIGN.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      )
    )
    .catch(function (error) {
      console.log('funcSuggestedOrderCampaignGet error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as SuggestedOrder);
};

export const funcShoppingCartCreate = async (
  accountProviderId: number,
  accountId: string,
  shoppingCart: PostShoppingCartCreate
): Promise<ShoppingCart | undefined> => {
  var response = await framework_services_axios
    .post(
      FRAMEWORK_POST_SHOPPING_CART_CREATE.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ),
      shoppingCart
    )
    .catch(function (error) {
      console.log('funcShoppingCartCreate error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as ShoppingCart);
};

export const funcRegisterPostCDS = async (
  accountProviderId: number,
  accountId: string,
  cdsCustomer: PostCDSCustomer
): Promise<RegisterAccount | undefined> => {
  var response = await framework_services_axios
    .post(
      FRAMEWORK_POST_CDS_CUSTOMER.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ),
      cdsCustomer
    )
    .catch(function (error) {
      console.log('funcRegisterPostCDS error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as RegisterAccount);
};

export const funcRegisterPostB2C = async (
  accountProviderId: number,
  accountId: string,
  b2cCustomer: PostB2CCustomer
): Promise<RegisterAccount | undefined> => {
  var response = await framework_services_axios
    .post(
      FRAMEWORK_POST_B2C_CUSTOMER.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ),
      b2cCustomer
    )
    .catch(function (error) {
      console.log('funcRegisterPostB2C error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as RegisterAccount);
};

export const funcAccountAddressPost = async (
  accountProviderId: number,
  accountId: string,
  address: EcommerceAddress
): Promise<any | ErrorRequestResponse> => {
  try {
    const response = await framework_services_axios.post(
      FRAMEWORK_POST_SAVE_ADDRESS.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ),
      address
    );
    return response;
  } catch (error) {
    const err = error as AxiosError;
    return err.response as ErrorRequestResponse;
  }
};

export const funcPaymentPostTokenize = async (
  accountProviderId: number,
  accountId: string,
  paymentTokenize: PostPaymentTokenize
): Promise<PaymentTokenize | undefined> => {
  var response = await framework_services_axios
    .post(
      FRAMEWORK_POST_PAYMENT_TOKENIZE.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ),
      paymentTokenize
    )
    .catch(function (error) {
      console.log('funcPaymentPostTokenize error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as PaymentTokenize);
};

export const funcPaymentPostVerify = async (
  accountProviderId: number,
  accountId: string,
  token: string
): Promise<PaymentVerify | undefined> => {
  var response = await framework_services_axios
    .post(
      FRAMEWORK_POST_PAYMENT_VERIFY.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ),
      {token}
    )
    .catch(function (error) {
      console.log('funcPaymentPostVerify error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as PaymentVerify);
};


export const funcOrderCheckout = async (
  accountProviderId: number,
  accountId: string,
  cart: EcommerceProduct[],
  deliveryAddress: EcommerceAddress,
  total: number,
  invoice: BillingInformation,
  selectedDeliveryDate: string,
  paymentMethod: string,
  paymentAdditionalInformation?: any,
): Promise<ErrorRequestResponse | EcommerceOrder> => {
  var errorResult;
  var shippingAddress = CheckoutShippingAddress.fromEcommerceAddress(deliveryAddress);
  var response = await framework_services_axios
    .post(
      FRAMEWORK_POST_CHECKOUT_PROCESS.replace('{accountProvider}', accountProviderId.toString()).replace(
        '{accountId}',
        accountId
      ),
      {
        origin: 'webview',
        email: accountId, //TODO could be eliminated in Services
        creation_date: '', //TODO could be eliminated in Services
        internal_order_id: 0,
        total, //TODO could be eliminated in Services
        PaymentMethod: paymentMethod,
        payment_additional_information: paymentAdditionalInformation,
        products: cart.map((product) => CheckoutEcommerceProduct.fromEcommerceProduct(product)),
        shippingAddress,
        billingAddress: CheckoutBillingAddress.fromBillingAddress(
          invoice?.billingAddress as BillingAddress,
          shippingAddress
        ), //TODO make optional in F. Services
        billing: CheckoutBillingInformation.fromBillingInformation(invoice as BillingInformation),
        deliveryDate: selectedDeliveryDate,
      }
    )
    .catch(function (error) {
      errorResult = error.response;
    });
  if (errorResult !== undefined) return errorResult;

  return response as any as EcommerceOrder;
};

export const funcProviderAvailabilityZoneGetSettlements = async (
  accountProviderId: number,
  countryId: string,
  postalCode: string
): Promise<Settlement[] | undefined> => {
  var response = await framework_services_axios
    .get(
      FRAMEWORK_GET_SETTLEMENTS.replace('{accountProvider}', accountProviderId.toString())
        .replace('{countryId}', countryId)
        .replace('{postalCode}', postalCode)
    )
    .catch((error) => {
      console.log('funcAccountAddressPost error = ', error);
      return undefined;
    });

  return response === undefined ? undefined : (response as any as Settlement[]);
};

export const funcFinishFlow = async (contextId: string, order: EcommerceOrder): Promise<boolean | undefined> => {
  var payload = {
    status: 'Finished',
    origin: 'webview',
    order,
  };

  var response = await framework_services_axios
    .post(FRAMEWORK_POST_FINISH_FLOW.replace('{contextId}', contextId), payload)
    .catch(function (error) {
      return undefined;
    });

  var result = response === undefined ? undefined : (response as any as string);
  return result === 'Success';
};

export const funcNotificationPost = async (
  accountProviderId: number,
  templateName: string,
  phoneNumber: string
): Promise<boolean> => {
  var payload = {
    templateName,
    phoneNumber,
  };

  var response = await framework_services_axios
    .post(FRAMEWORK_POST_NOTIFICATION.replace('{accountProvider}', accountProviderId?.toString()), payload)
    .catch(function (error) {
      console.log('funcNotificationPost error = ', error);
      return undefined;
    });

  return response === undefined ? false : true;
};