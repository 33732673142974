import { useEffect, useState } from 'react';

export const useBottomScroll = () => {
  const [isBottomScroll, setIsBottomScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      window.innerHeight + window.scrollY >= document.body.offsetHeight
        ? setIsBottomScroll(true)
        : setIsBottomScroll(false);
    };
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return isBottomScroll;
};
