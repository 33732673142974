import React from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { cdsIdConfig } from "cds/cdsInit";
import { AddressFormPage } from "features/pages/AddressFormPage";
import { B2CCDSPage } from "features/pages/B2CCDSPage";
import { CreateUserPage } from "features/pages/CreateUserPage";
import { GeofencingPage } from "features/pages/GeofencingPage";
import { HomePage } from "features/pages/HomePage";
import { LoginUserPage } from "features/pages/LoginUserPage";
import { PDF, PDFType } from "../features/pages/PDF";
import { ProductsPage } from "../features/pages/ProductsPage";
import { RootState } from "./store";

import { ThemeProvider } from "@material-ui/core/styles";
import { DocumentViewer } from "features/main/DocumentViewer";
import { AuthenticationPage } from "features/pages/AuthenticationPage";
import { EcommercePage } from "features/pages/EcommercePage";
import ecommerceTheme from "./tccc-theme";
import { PaymentPage } from "features/pages/PaymentPage";

function App() {
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const { accountProvider } = useSelector((state: RootState) => state.account);
  const searchParams = new URLSearchParams(window.location.search);
  const contextId = searchParams.get("contextId") || "";
  var gtmCode = process.env["REACT_APP_GTM_ID_" + accountProvider?.country_id] || "";
  var gtmAuth = process.env["REACT_APP_GTM_AUTH_" + accountProvider?.country_id] || "";
  var gtmPreview = process.env["REACT_APP_GTM_PREVIEW_" + accountProvider?.country_id] || "";
  const tagManagerArgs = {
    gtmId: gtmCode,
    dataLayer: {
      userId: contextId,
      bottler: deliveryData?.bottler,
    },
    auth: gtmAuth,
    preview: gtmPreview,
  };

  TagManager.initialize(tagManagerArgs);

  //
  return (
    <ThemeProvider theme={ecommerceTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EcommercePage />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductsPage />} />
          </Route>
          <Route path="/geofencing" element={<GeofencingPage />} />
          <Route path="/address-form" element={<AddressFormPage />} />
          <Route path="/customer/account/login" element={<LoginUserPage config={cdsIdConfig} />} />
          <Route path="/customer/account/create" element={<CreateUserPage config={cdsIdConfig} />} />
          <Route path="/customer/account/b2c" element={<B2CCDSPage />} />
          <Route path="/customer/payment" element={<PaymentPage />} />
          <Route path="/chatbot" element={() => <PDF type={PDFType.ChatbotPromo} />} />
          <Route path="/document" element={<DocumentViewer />} />
          <Route path="/authentication" element={<AuthenticationPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
