import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import { initB2Ccds } from "cds/cdsB2Csdk/b2cInit";
import { funcFinishFlow, funcOrderCheckout, funcPaymentPostTokenize } from "features/common/API";
import { formatNumber } from "features/common/formatHelpers";
import { GTM_LIST_NAME_SHOPPING_CART } from "features/common/gtmEventHandler";
import { clearCart, removeAllProducts } from "features/common/productSlice";
import { AddressFormPageLink, PaymentWrapperPageLink, BotWhatsAppLink, CDSLoginRelativeLink } from "features/common/urlBuilder";
import { useBottomScroll } from "features/hooks/useBottomScroll";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CartIllustration from "../../assets/cart_empty.svg";
import currency from "../../assets/pesos_svg.svg";
import { CartProduct } from "./CartProduct";
import { CheckoutProgress } from "./CheckoutProgress";
import { Delivery } from "./Delivery";
import { MERCADO_PAGO_WRAPPER_PAYMENT_CODE, Payment } from "./Payment";
import { FullPageLoader, LoaderType } from "./FullPageLoader";
import { MessageDialog } from "./MessageDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    maxWidth: "500px",
    textAlign: "center",
    backgroundColor: "#F0F0F0",
    margin: "auto",
  },
  confirmOrderCard: {
    padding: "0px 0px 16px 0px",
    //paddingTop: theme.spacing(1),
    maxWidth: "500px",
    textAlign: "center",
    backgroundColor: "#F0F0F0",
    marginBottom: theme.spacing(7),
    margin: "auto",
  },
  orderActions: {
    justifyContent: "center",
  },
  priceDetails: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontWeight: 400,
  },
  priceTotal: {
    marginTop: theme.spacing(1),
    fontWeight: 400,
    textAlign: "left",
  },
  invoiceFormTextField: {
    marginBottom: theme.spacing(2),
  },
  invoiceFormRadioField: {
    marginTop: theme.spacing(1),
  },
  invoiceRadioGroup: {
    flexDirection: "row",
  },
  invoiceRadio: {
    ...inputClasses.radioButton,
  },
  invoiceCheckbox: {
    ...inputClasses.checkBox,
  },
  dialogActions: {
    justifyContent: "center",
  },
  billingAddress: {
    textAlign: "justify",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  billingAddressField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  grid: {
    justifyContent: "center",
  },
  gridImage: {
    textAlign: "right",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "6px",
    paddingRight: "5px",
  },
  gridText: {
    textAlign: "left",
    justifyContent: "center",
    alignItems: "center",
  },
  textFieldLabelRoot: {
    ...inputClasses.textFieldLabel.root,
  },
  textFieldLabelFocus: {
    ...inputClasses.textFieldLabel.focus,
  },
  textFieldLabelError: {
    ...inputClasses.textFieldLabel.error,
  },
  textFieldInputRoot: {
    ...inputClasses.textFieldInput.root,
  },
  textFieldInputOutlined: {
    ...inputClasses.textFieldInput.notchedOutline,
  },
  textFieldInputFocus: {
    ...inputClasses.textFieldInput.focus,
  },
  textFieldInputError: {
    ...inputClasses.textFieldInput.error,
  },
  cartImage: {
    height: "50%",
    width: "25%",
    paddingTop: "16px",
    paddingBottom: "8px",
  },
  confirmOrderButtonContent: {
    margin: "8px 0px 0px 0px",
    width: "75%",
    height: 44,
    backgroundColor: "#E02020",
  },
  mobileStylePrimary: {
    ...inputClasses.mobileStylePrimary,
    whiteSpace: "nowrap",
  },
  infoMessage: {
    ...inputClasses.infoMessage,
    display: "flex",
    flexWrap: "initial",
  },
  infoIconGrid: {
    alignItems: "center",
    display: "flex",
  },
  iconImage: {
    height: "21px",
    width: "21px",
    marginRight: "4px",
    marginLeft: "4px",
    alignItems: "center",
  },
  paymentMethodTypography: {
    fontSize: "14px",
    textAlign: "left",
  },
}));

export const Checkout = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Loader State
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  // Modal Controls
  const [isPopUpOrderConfirmationOpen, setIsPopUpOrderConfirmationOpen] = useState(false);
  const [isPopUpB2CConfirmationOpen, setIsPopUpB2CConfirmationOpen] = useState(false);

  const { cart, subtotal, shipping } = useSelector((state: RootState) => state.products);
  const { deliveryData, selectedPaymentMethod } = useSelector((state: RootState) => state.deliveryData);
  const dispatch: AppDispatch = useDispatch();

  const { accountProvider, conversationContext, registerAccount } = useSelector((state: RootState) => state.account);
  const { selectedDeliveryDate, billingInformation } = useSelector((state: RootState) => state.deliveryData);
  const [minimumOrderAmount, setMinimumOrderAmount] = useState(accountProvider?.minimum_order_amount);
  const [isCartEmpty, setIsCartEmpty] = useState(true);

  const isBottomScroll = useBottomScroll();

  const bottlers = JSON.parse(accountProvider?.bottlers || "[]");
  const bottlerPaymentMethod = bottlers.find(
    (bottler: { warehouse_code: string | undefined }) => bottler.warehouse_code === deliveryData.warehouse_code
  )?.payment_method;
  const defaultPaymentMethod = "El pago es contra entrega";

  const renderCart = () => {
    const cartIds = Object.keys(cart);
    return cartIds.map((cartId, index) => (
      <CartProduct
        key={cartId}
        product={cart[cartId]}
        position={GTM_LIST_NAME_SHOPPING_CART}
        viewPosition={index + 1}
      />
    ));
  };

  useEffect(() => {
    const cartIds = Object.keys(cart);
    cartIds.length > 0 ? setIsCartEmpty(false) : setIsCartEmpty(true);
  }, [cart]);

  const validateCart = async () => {
    const cartProducts = Object.keys(cart).map((id) => cart[id]);
    if (cartProducts.length === 0) {
      setIsErrorMessageOpen(true);
      setErrorMessage("No hay productos en tu carrito");
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Ok");
    } else {

      // Unregistered user => Go to CDS Login/Register or B2C Login/Register
      if (!registerAccount || !registerAccount?.account_id) {
        if (accountProvider?.registrationFlow === "b2c") {
          const sdk = await initB2Ccds(accountProvider?.key);
          sdk.getAuthenticationUrl(true, false);
        } else {
          navigate(CDSLoginRelativeLink());
        }
        return;
      }

      // Registered user that wants to create a new address => Go to AddressFormPage
      if (deliveryData.is_new) {
        //navigate(AddressFormRelativeLink());
        window.location.assign(AddressFormPageLink());
        return;
      }

      if (selectedPaymentMethod === MERCADO_PAGO_WRAPPER_PAYMENT_CODE) {
        const paymentTokenize: PostPaymentTokenize = {
          bottler_name: deliveryData.bottler as string,
          cart_id: "empty_cart_id", //TODO: Reorganize checkout flow to get cart_id from cart
          amount: subtotal,
        };

        var paymentTokenResponse = await funcPaymentPostTokenize(
          conversationContext?.accountProviderId as number,
          registerAccount?.account_id as string,
          paymentTokenize as PostPaymentTokenize,
        );

        if (paymentTokenResponse) {
          //Get token correctly
          window.location.replace(PaymentWrapperPageLink(accountProvider?.country_id, paymentTokenResponse.token as string));
        } else {
          //Error getting token - redirect to login (Authentication expired or missing)
          setIsPopUpB2CConfirmationOpen(true);
        }
        return;
      }

      setIsPopUpOrderConfirmationOpen(true);
    }
  };

  const checkout = async () => {
    const cartProducts = Object.keys(cart).map((id) => cart[id]);
    if (cartProducts.length === 0) {
      setIsErrorMessageOpen(true);
      setErrorMessage("No hay productos en tu carrito");
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Ok");
    } else {
      // Unregistered user => Go to CDS Login/Register or B2C Login/Register
      if (!registerAccount || !registerAccount?.account_id) {
        if (accountProvider?.registrationFlow === "b2c") {
          const sdk = await initB2Ccds(accountProvider?.key);
          sdk.getAuthenticationUrl(true, false);
        } else {
          navigate(CDSLoginRelativeLink());
        }
        return;
      }

      // Registered user that wants to create a new address => Go to AddressFormPage
      if (deliveryData.is_new) {
        //navigate(AddressFormRelativeLink());
        window.location.assign(AddressFormPageLink());
        return;
      }
      const billingAddressData: BillingAddress = {
        street: [],
        number: "",
        number_int: "",
        postcode: "",
        neighborhood: "",
        municipality: "",
        region: "",
        region_id: "",
        region_code: "",
        city: "",
      };
      const mockInvoiceData: BillingInformation = {
        rfc: "",
        rfc_type: "",
        billing_name: "",
        tax_regime: "",
        tax_cfdi_use: "",
        tax_capital_regime: "",
        want_bill: false,
        billingAddress: billingAddressData,
      };

      // Registered user with a existing address => Checkout + Send to Whatsapp
      setIsLoaderActive(true);
      window.onbeforeunload = () => true;
      var checkoutResponse = await funcOrderCheckout(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        cartProducts,
        deliveryData,
        subtotal,
        billingInformation ? billingInformation : mockInvoiceData,
        selectedDeliveryDate,
        selectedPaymentMethod,
      );

      if (typeof checkoutResponse.status === "string") {
        //CheckoutOrder gives OK
        const result = checkoutResponse as EcommerceOrder;

        const CheckoutResult = {
          products: cartProducts,
          transactionId: result.supplier_order_id,
          countryId: accountProvider?.country_id,
          currency: accountProvider?.currency,
        };
        dispatch(clearCart(CheckoutResult));
        var contextId: string = conversationContext?.contextId as string;

        window.onbeforeunload = () => undefined;
        window.location.replace(BotWhatsAppLink(conversationContext?.whatsappNumber));
      } else {
        //CheckoutOrder gives not OK
        window.onbeforeunload = () => undefined;
        var errorMessage: string = (checkoutResponse as ErrorRequestResponse).data.error.toString();
        if (checkoutResponse && checkoutResponse.status === 400 && errorMessage.includes("Error ocurred adding the product with name") === true ) {
          var productName = errorMessage.slice(errorMessage.indexOf("name ") + 5);
          if (productName) {
            setIsErrorMessageOpen(true);
            setErrorMessage(`Lo sentimos 😔, el producto ${productName} esta agotado temporalmente.`);
            setErrorMessageButtonLink("");
            setErrorMessageButtonText("Ok");

            const productToRemoveIndex = cartProducts.findIndex((product) => product.name === productName);
            if (productToRemoveIndex >= 0) {
              const productToRemove = cartProducts[productToRemoveIndex];
              dispatch(
                removeAllProducts({
                  product: productToRemove!,
                  position: "Checkout",
                  viewPosition: productToRemoveIndex + 1,
                  countryId: accountProvider?.country_id,
                  currency: accountProvider?.currency,
                })
              );
            }
          }
        } else {
          // Unexpected error on Checkout call
          setIsErrorMessageOpen(true);
          setErrorMessage(`Lo sentimos 😔, no he podido crear tu pedido. Puedes intentarlo nuevamente más tarde.`);
          setErrorMessageButtonLink("");
          setErrorMessageButtonText("Ok");
        }
        setIsLoaderActive(false);
      }
    }
  };

  const loginB2C = async () => {
    if (accountProvider?.registrationFlow === "b2c") {
      const sdk = await initB2Ccds(accountProvider?.key);
      sdk.getAuthenticationUrl(true, false);
    } else {
      navigate(CDSLoginRelativeLink());
    }
  };

  return (
    <>
      <Card id="cart-container" className={classes.root}>
        <Typography variant="h4">
          <b>Mi carrito</b>
        </Typography>
        <br />
        {renderCart()}
        {isCartEmpty ? (
          <Grid>
            <Typography>Tu carrito está vacío,</Typography>
            <Typography>¡Agrega productos!</Typography>
            <img className={classes.cartImage} alt="cart" src={CartIllustration} />
          </Grid>
        ) : null}

        <div className={classes.priceDetails}>
          <Typography>
            <b>Subtotal:</b> {accountProvider?.currency_symbol}
            {formatNumber(subtotal, accountProvider?.amount_format, accountProvider?.amount_separator)}
          </Typography>
          <Typography>
            <b>Envio:</b> {shipping === 0 ? "Gratis" : `${accountProvider?.currency_symbol}${shipping.toFixed(2)}`}
          </Typography>
        </div>
        <Divider />
        <Typography className={classes.priceTotal}>
          <b>Total:</b> {accountProvider?.currency_symbol}
          {formatNumber(subtotal + shipping, accountProvider?.amount_format, accountProvider?.amount_separator)}
        </Typography>

        {minimumOrderAmount !== 0 && (
          <Grid>
            <br />
            <CheckoutProgress
              subtotal={subtotal}
              subtotalFormatted={`${accountProvider?.currency_symbol}${formatNumber(
                subtotal,
                accountProvider?.amount_format,
                accountProvider?.amount_separator
              )}`}
              minimumOrderAmount={minimumOrderAmount}
              minimumOrderAmountFormatted={`${accountProvider?.currency_symbol}${formatNumber(
                minimumOrderAmount as number,
                accountProvider?.amount_format,
                accountProvider?.amount_separator
              )}`}
            />
            <br />
            <Typography variant="body1">
              <img style={{ verticalAlign: "middle" }} src={currency} alt="" />{" "}
              <strong>
                El monto mínimo de compra es de {accountProvider?.currency_symbol}
                {formatNumber(
                  minimumOrderAmount as number,
                  accountProvider?.amount_format,
                  accountProvider?.amount_separator
                )}
              </strong>
            </Typography>
          </Grid>
        )}
      </Card>

      <Delivery />
      
      {(registerAccount?.account_id !== undefined && !deliveryData.is_new) && (
        <Payment />
      )}  
      
      {cart && Object.keys(cart).length !== 0 && (
        <Card className={classes.confirmOrderCard}>
          <Button
            className={classes.confirmOrderButtonContent + " " + classes.mobileStylePrimary}
            color="primary"
            disabled={subtotal + shipping < (minimumOrderAmount || 0) || isLoaderActive || (selectedPaymentMethod === "" && (registerAccount?.account_id !== undefined && !deliveryData.is_new))}
            variant="contained"
            onClick={() => validateCart()}
            style={{ width: isBottomScroll && isMobile ? "100%" : "75%" }}
          >
            Proceder a Ordenar
          </Button>
          {isLoaderActive ? (
            <FullPageLoader
              type={LoaderType.Authentication}
              text="Creando pedido... Por favor permanece en esta página hasta que finalice la operación."
            />
          ) : (
            <></>
          )}
        </Card>
      )}

      <Dialog open={isPopUpOrderConfirmationOpen}>
        <DialogContent>
          <DialogContentText color={"textPrimary"}>
            Por favor da click para confirmar tu orden {" "}
            <span role="img"> 👇 </span>
            <br />
            {/* TODO to define logic defaultPaymentMethod

              selectedPaymentMethod === "Efectivo" && bottlerPaymentMethod !== undefined && bottlerPaymentMethod !== null
              ? "El pago es " + bottlerPaymentMethod
              : defaultPaymentMethod
            */}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="secondary"
            onClick={() => {
              setIsPopUpOrderConfirmationOpen(false);
            }}
          >
            Volver
          </Button>
          <Button
            className={classes.mobileStylePrimary}
            color="primary"
            onClick={() => {
              setIsPopUpOrderConfirmationOpen(false);
              checkout();
            }}
          >
            Confirmar Pedido
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isPopUpB2CConfirmationOpen}>
        <DialogContent>
          <DialogContentText color={"textPrimary"}>
            Por seguridad debes iniciar sesión para mostrar los datos de pago con tarjeta
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="secondary"
            onClick={() => {
              setIsPopUpB2CConfirmationOpen(false);
            }}
          >
            Volver
          </Button>
          <Button
            className={classes.mobileStylePrimary}
            color="primary"
            onClick={() => {
              setIsPopUpB2CConfirmationOpen(false);
              loginB2C();
            }}
          >
            Ir a iniciar sesión
          </Button>
        </DialogActions>
      </Dialog>

      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  );
};
