import { Container, createStyles, CssBaseline, Grid, makeStyles } from "@material-ui/core";
import { setAccountInitialState } from "features/common/accountSlice";
import { setCategoryInitialState } from "features/common/categorySlice";
import { setDeliveryInitialState } from "features/common/deliverySlice";
import { setGeofencingInitialState } from "features/common/geofencingSlice";
import { setProductsInitialState } from "features/common/productSlice";
import Map from "features/main/Map";
import { Navbar } from "features/main/Navbar";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    background: {
      width: "100%",
      height: "100%",
      minHeight: "650px",
      position: "absolute",
      top: "66px",
      [theme.breakpoints.up("sm")]: {
        bottom: 0,
      },
    },
  })
);

export const GeofencingPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // Set initial state on all the Slices at the beginning of the GeofencingPage
    (() => {
      const searchParams = new URLSearchParams(window.location.search);
      const returnUrl = searchParams.get("returnUrl");

      if (returnUrl) {
        return;
      }

      dispatch(setAccountInitialState());
      dispatch(setDeliveryInitialState());
      dispatch(setProductsInitialState());
      dispatch(setCategoryInitialState());
      dispatch(setGeofencingInitialState());
    })();
  }, []);

  return (
    <>
      <CssBaseline />
      <Navbar />
      <main className={classes.background}>
        <Container className={classes.gridContainer}>
          <Grid container spacing={0} direction="column" justify="center" style={{ minHeight: "75vh" }}>
            <Map center={{ lat: undefined, lng: undefined }} height="350px" zoom={18}></Map>
          </Grid>
        </Container>
      </main>
    </>
  );
};
