import { Button, ButtonGroup, Fade, makeStyles, Typography } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { addToCart, removeProduct } from "features/common/productSlice";
import { EcommerceProduct } from "models/ecommerceProduct";
import React, { useState } from "react";
import { ChildButton, Directions, FloatingMenu, MainButton } from "react-floating-button-menu";
import { useDispatch, useSelector } from "react-redux";
import { AlcoholicValidationComponent } from "./AlcoholicValidationDialog";
import AddIconWhite from "../../assets/addIconWhite.svg";
import MinusIconWhite from "../../assets/minusIconWhite.svg";
import AddIcon from "../../assets/addIcon.svg";
import MinusIcon from "../../assets/minusIcon.svg";
import SuccessIcon from "../../assets/successIcon.svg";
import { MessageDialog } from "./MessageDialog";
import { funcShoppingCartCreate } from "features/common/API";
import { PostShoppingCartCreate } from "models/ecommerceCart";

const useStyles = makeStyles((theme) => ({
  floatingMenuWrapper: {
    "& ul": {
      "& a": {
        zIndex: 1,
      },
    },
  },
  floatingProductButton: {
    color: "#FFFFFF",
    height: "40px",
    width: "40px",
    textAlign: "center",
    borderRadius: "16px",
    background: "black",
    cursor: "default",
    transition: "1s",
    WebkitTransition: "1s",
    MozTransition: "1s",
    zIndex: 1
  },
  addIconWhite: {
    height: "40px",
    witdth: "40px",
    padding: "12px",
    transition: "1s",
    WebkitTransition: "1s",
    MozTransition: "1s",
  },
  addIcon: {
    height: "40px",
    witdth: "40px",
    padding: "12px",
  },
  minusIconWhite: {
    height: "40px",
    witdth: "40px",
    minWidth: "40px",
    maxWidth: "40px",
    padding: "12px",
  },
  minusIcon: {
    height: "40px",
    witdth: "40px",
    padding: "12px",
  },
  floatingProductButtonActive: {
    color: "#FFFFFF",
    height: "40px",
    width: "40px",
    textAlign: "center",
    borderRadius: "16px",
    background: "black",
    cursor: "default",
    transition: "1s",
    WebkitTransition: "1s",
    MozTransition: "1s",
    transform: "rotate(180deg)",
    WebkitTransform: "rotate(180deg)",
    MsTransform: "rotate(180deg)",
    zIndex: 1,
  },
  floatingProductAmountButton: {
    color: "black",
    height: "40px",
    minWidth: "95px",
    textAlign: "center",
    padding: "6px",
    borderRadius: "16px",
    background: "white",
    border: "2px solid",
    cursor: "default",
    zIndex: 0,
  },
  floatingProductButtonAdded: {
    color: "#237722",
    position: "absolute",
    zIndex: 2,
    height: "40px",
    width: "40px",
    textAlign: "center",
    padding: "6px",
    borderRadius: "16px",
    background: "#C5FFC4",
    border: "2px solid black",
    cursor: "default",
    transform: "rotate(180deg)",
    WebkitTransform: "rotate(180deg)",
    MsTransform: "rotate(180deg)",
  },
  successIcon: {
    paddingTop: "4px",
  },
  buttonGroup: {
    marginTop: "4px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    height: "40px",
  },
  productButtonItem: {
    border: "0",
    padding: "0px",
    fontWeight: 500,
    width: "40px",
    height: "40px",
  },
  productButton: {
    color: "#FFFFFF",
    height: 40,
    width: 40,
    minWidth: 40,
    textAlign: "center",
    borderRadius: "12px",
    background: "black",
    cursor: "default",
  },
  productAmountButton: {
    color: "black",
    height: 40,
    width: 40,
    minWidth: 40,
    textAlign: "center",
    borderRadius: "12px",
    background: "white",
    border: "2px solid",
    cursor: "default",
  },
}));

export const FloatingButtonGroup = ({
  product,
  position,
  viewPosition,
  closeDelay,
  sucessAnimationDelay,
}: {
  product: EcommerceProduct;
  position?: string;
  viewPosition: number;
  closeDelay: number;
  sucessAnimationDelay: number;
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(undefined);
  const [sendClick, setSendClick] = useState<boolean>(true);

  const [successAnimationIsOpen , setSuccessAnimationIsOpen] = useState(false);
  const [successAnimationTimerId, setSuccessAnimationTimerId] = useState<NodeJS.Timeout | undefined>(undefined);

  let { cart, cartId } = useSelector((state: RootState) => state.products);
  const { conversationContext, registerAccount, accountProvider } = useSelector((state: RootState) => state.account);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  const dispatch: AppDispatch = useDispatch();

  const validateAddToCart = () => {
    return (cart[product.product_id]?.cartCount ?? 0) + 1 <= product.stock;
  }

  const onClickAdd = async () => {
    if (!validateAddToCart()) {
      setIsErrorMessageOpen(true);
      setErrorMessage(`Solo puedes comprar un máximo de ${product.stock} piezas para el producto: ${product.name}`);
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Cerrar");
      return;
    }

    // Prevent multiple create cart calls
    if (!cartId && successAnimationIsOpen) {
      return;
    }

    if (successAnimationTimerId) clearTimeout(successAnimationTimerId);

    setSuccessAnimationTimerId(
      setTimeout(() => {
        setSuccessAnimationIsOpen(false);
      }, sucessAnimationDelay)
    )
    setSuccessAnimationIsOpen(true);

    // Create cart if it doesn't exist
    if (!cartId) {
      var payload = {
        outlet: deliveryData?.encoded_route_info,
        store: deliveryData?.bottler,
        products: [],
      } as PostShoppingCartCreate;
      
      const shoppingCart = await funcShoppingCartCreate(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        payload
      );

      cartId = shoppingCart?.supplier_cart_id ?? cartId;
    }

    dispatch(
      addToCart({
        product: product,
        position: position,
        viewPosition: viewPosition,
        countryId: accountProvider?.country_id,
        currency: accountProvider?.currency,
        sendClick: sendClick,
        cartId: cartId,
      })
    );
    setSendClick(false);
  };

  const onClickSubstract = () => {
    dispatch(
      removeProduct({
        product: product,
        position: position,
        viewPosition: viewPosition,
        countryId: accountProvider?.country_id,
        currency: accountProvider?.currency,
        sendClick: sendClick,
      })
    );
    setSendClick(false);
  };

  // It restarts the timer after the floating button will be closed.
  const onClickWrapper = (onClick: () => void) => {
    return () => {
      if (timerId) clearTimeout(timerId);

      setTimerId(
        setTimeout(() => {
          setIsOpen(false);
        }, closeDelay)
      );

      onClick();
    };
  };

  const floatingButtonOnClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
    onClickWrapper(onClickAdd)();
  };

  const renderFloatingButtonGroup = (onClickAddEvent: () => void) => {
    return (
      <div className={classes.floatingMenuWrapper}>
        <FloatingMenu slideSpeed={1000} direction={Directions.Left} spacing={0} isOpen={isOpen}>
          <MainButton
            iconResting={
              <div id={product.product_id.toString()} className={classes.floatingProductButton}>
                <img className={classes.addIconWhite} alt="logo" src={AddIconWhite} />
              </div>
            }
            iconActive={
              <div>
                <Fade in={successAnimationIsOpen} timeout={300}>
                  <div id={product.product_id.toString()} className={classes.floatingProductButtonAdded}>
                    <img className={classes.successIcon} alt="logo" src={SuccessIcon}/>
                  </div>
                </Fade>
                <div id={product.product_id.toString()} className={classes.floatingProductButtonActive}>
                    <img className={classes.addIconWhite} alt="logo" src={AddIconWhite} />
                  </div>
              </div>
            }
            background="black"
            onClick={onClickAddEvent}
            size={40}
          />
          <ChildButton
            icon={
              <Typography variant={"body1"} className={classes.floatingProductAmountButton}>
                {cart[product.product_id]?.cartCount ?? 0}
              </Typography>
            }
            background="white"
            size={40}
          />
          <ChildButton
            icon={
              <div id={product.product_id.toString()} className={classes.floatingProductButtonActive}>
                <img className={classes.minusIconWhite} alt="logo" src={MinusIconWhite} />
              </div>
            }
            background="black"
            size={40}
            onClick={() => onClickWrapper(onClickSubstract)()}
          />
        </FloatingMenu>
        {isErrorMessageOpen ? (
          <MessageDialog
            message={errorMessage}
            link={errorMessageButtonLink}
            text={errorMessageButtonText}
            setIsDialogActive={setIsErrorMessageOpen}
          />
        ) : null}
      </div>
    );
  };

  return product.is_alcoholic === true ? (
    <AlcoholicValidationComponent
      render={renderFloatingButtonGroup}
      validationPassedCallback={floatingButtonOnClick}
    />
  ) : (
    renderFloatingButtonGroup(floatingButtonOnClick)
  );
};

export const ProductButtonGroup = ({ product, position, viewPosition }: { product: EcommerceProduct; position?: string; viewPosition: number }) => {
  const classes = useStyles();
  let { cart, cartId } = useSelector((state: RootState) => state.products);
  const { conversationContext, registerAccount, accountProvider } = useSelector((state: RootState) => state.account);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const [sendClick, setSendClick] = useState<boolean>(true);

  const dispatch: AppDispatch = useDispatch();

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  const validateAddToCart = () => {
    return (cart[product.product_id]?.cartCount ?? 0) + 1 <= product.stock;
  }

  const onClickAdd = async () => {
    if (!validateAddToCart()) {
      setIsErrorMessageOpen(true);
      setErrorMessage(`Solo puedes comprar un máximo de ${product.stock} piezas para el producto: ${product.name}`);
      setErrorMessageButtonLink("");
      setErrorMessageButtonText("Cerrar");
      return;
    }

    // Create cart if it doesn't exist
    if (!cartId) {
      var payload = {
        outlet: deliveryData?.encoded_route_info, 
        store: deliveryData?.bottler,
        products: [],
      } as PostShoppingCartCreate;
      
      const shoppingCart = await funcShoppingCartCreate(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        payload
      );

      cartId = shoppingCart?.supplier_cart_id ?? cartId;
    } 

    dispatch(
      addToCart({
        product: product,
        position: position,
        viewPosition: viewPosition,
        countryId: accountProvider?.country_id,
        currency: accountProvider?.currency,
        sendClick: sendClick,
        cartId: cartId,
      })
    );
    setSendClick(false);
  };

  const onClickSubstract = () => {
    dispatch(
      removeProduct({
        product: product,
        position: position,
        viewPosition: viewPosition,
        countryId: accountProvider?.country_id,
        currency: accountProvider?.currency,
        sendClick: sendClick
      })
    );
    setSendClick(false);
  };

  const renderProductButtonGroup = (onClickAddEvent: () => void) => {
    return (
      <ButtonGroup variant="outlined" className={classes.buttonGroup}>
        <Button className={classes.productButtonItem} onClick={() => onClickSubstract()}>
          <img className={classes.minusIcon} alt="logo" src={MinusIcon} />
        </Button>
        <Button className={classes.productButtonItem}>
          <Typography variant="body1">
            {cart[product.product_id]?.cartCount ?? 0}
          </Typography>
        </Button>
        <Button className={classes.productButtonItem} onClick={() => onClickAddEvent()}>
          <img className={classes.addIcon} alt="logo" src={AddIcon} />
        </Button>
        {isErrorMessageOpen ? (
          <MessageDialog
            message={errorMessage}
            link={errorMessageButtonLink}
            text={errorMessageButtonText}
            setIsDialogActive={setIsErrorMessageOpen}
          />
        ) : null}
      </ButtonGroup>
    );
  };

  return product.is_alcoholic === true ? (
    <AlcoholicValidationComponent render={renderProductButtonGroup} validationPassedCallback={onClickAdd} />
  ) : (
    renderProductButtonGroup(onClickAdd)
  );
};
