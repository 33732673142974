import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { inputClasses } from "app/tccc-theme";
import CancelIcon from "assets/cancelIcon.svg";
import React, { useState } from "react";
import ErrorIcon from "../../assets/icons/ui/errorIcon.svg";
import InfoIcon from "../../assets/icons/ui/infoIcon.svg";
import SuccessIcon from "../../assets/icons/ui/successIcon.svg";
import WarningIcon from "../../assets/icons/ui/warningIcon.svg";

const useStyles = makeStyles((theme) => ({
  infoMessage: {
    ...inputClasses.infoMessage,
    display: "flex",
    flexWrap: "initial",
    paddingLeft: "16px",
  },
  successMessage: {
    ...inputClasses.successMessage,
    display: "flex",
    flexWrap: "initial",
    paddingLeft: "16px",
  },
  errorMessage: {
    ...inputClasses.errorMessage,
    display: "flex",
    flexWrap: "initial",
    paddingLeft: "16px",
  },
  warningMessage: {
    ...inputClasses.warningMessage,
    display: "flex",
    flexWrap: "initial",
    paddingLeft: "16px",
  },
  cancelIcon: {
    padding: "0px",
    width: "24px",
    height: "16px",
  },
  cancelIconButton: {
    width: "32px",
    minWidth: "32px",
    padding: "0px",
  },
  iconGrid: {
    alignItems: "center",
    display: "flex",
  },
  iconImage: {
    height: "22px",
    width: "22px",
    marginRight: "4px",
    marginLeft: "4px",
    alignItems: "center",
  },
}));

interface BannerProps {
  type: "info" | "success" | "error" | "warning";
  showIcon?: boolean;
  showCloseIcon?: boolean;
  message: string;
}

export const Banner = ({ type, message, showIcon, showCloseIcon }: BannerProps) => {
  const classes = useStyles();
  const [showBanner, setShowBanner] = useState(true);

  const messageType = {
    info: classes.infoMessage,
    success: classes.successMessage,
    error: classes.errorMessage,
    warning: classes.warningMessage,
  };

  const iconType = {
    info: InfoIcon,
    success: SuccessIcon,
    error: ErrorIcon,
    warning: WarningIcon,
  };

  return showBanner ? (
    <Grid item container direction="row" justifyContent="space-between" className={messageType[type]}>
      {showIcon ? (
        <Grid item className={classes.iconGrid}>
          <img className={classes.iconImage} alt="logo" src={iconType[type]} />
        </Grid>
      ) : null}
      <Grid item>
        <Typography style={{ whiteSpace: "pre-line" }} variant="body1">
          <strong>{message}</strong>
        </Typography>
      </Grid>
      {showCloseIcon ? (
        <Grid item style={{ display: "flex" }}>
          <Button className={classes.cancelIconButton} onClick={() => setShowBanner(false)}>
            <img src={CancelIcon} className={classes.cancelIcon} alt="X" />
          </Button>
        </Grid>
      ) : null}
    </Grid>
  ) : null;
};
