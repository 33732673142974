import { Container, CssBaseline, Grid } from '@material-ui/core';
import { Navbar } from 'features/main/Navbar';
import React from 'react';
import CDSLoginUser from '../main/LoginUser';

export const LoginUserPage = ({config}: {config: CDSIdConfig}) => {
	return (
		<>
			<CssBaseline/>
			<Navbar/>
			<main>
				<Container>
					<Grid>
						<CDSLoginUser config = {config}></CDSLoginUser> 
					</Grid>
				</Container>
			</main>
		</>
	);
};

