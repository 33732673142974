import { Fade, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import rightArrow from "assets/right_arrow.svg";
import lefttArrow from "assets/left_arrow.svg";
import "assets/hideScrollbar.css";
import { setSearchFilter } from "features/common/accountSlice";
import {
  toggleSelectedCategory,
  toggleSelectedOption,
  updateSelectedSubcategories,
} from "features/common/categorySlice";
import { GTM_LIST_NAME_CHILD_CATEGORY } from "features/common/gtmEventHandler";
import { filterProducts, setListName } from "features/common/productSlice";
import { EcommerceProduct } from "models/ecommerceProduct";
import React, { useEffect, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ProductsRelativeLink } from "../common/urlBuilder";
import { AlcoholicValidationComponent } from "./AlcoholicValidationDialog";

const useStyles = makeStyles((theme) => ({
  scrollMenu: {
    textAlign: "center",
    padding: "0 16px 0 16px",
  },
  arrow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    userSelect: "none",
    padding: "0px",
    border: "0px",
    position: "absolute",
    bottom: "10px",
    borderColor: "transparent",
    backgroundColor: "transparent",
    zIndex: 1,
  },
  arrowIcon: {
    borderRadius: "8px",
    height: "24px",
    width: "24px",
    [theme.breakpoints.up("md")]: {
      height: "28px",
      width: "28px"
    },
    paddingTop: "1px",
    backgroundColor: "#fafafa",
    color: "#000",
    textDecoration: "none",
    fill: "#000"
  },
  categoryTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    fontWeight: "400",
    whiteSpace: "nowrap",
    marginLeft: "0px",
    marginRight: "8px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 8,
    boxShadow: "rgb(0 0 0 / 25%) 5px 4px 6px -2px",
    color: "#000000",
    cursor: "pointer",
    fontStyle: "normal",
    lineHeight: "14px",
    paddingRight: "8px"
  },
  subcategoriesMenu: {
    padding: "0px",
    "& ul": {
      padding: "0px",
    },
  },
  listSubcategories: {
    minHeight: "12px",
    minWidth: "100%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#D4D4D4",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "16px",
    },
  },
  listCategories: {
    [theme.breakpoints.down("md")]: {
      minWidth: "100px",
      maxWidth: "250px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    marginTop: "0px",
    marginBottom: "10px",
  },
  categoryIcon: {
    width: "16px",
    height: "auto",
    margin: "4px 4px 4px 8px",
  },
}));

const tryImagePathRequire = async (categoryName) => {
  const iconName = categoryName
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/\s/g, "_");
  try {
    const categories = await import(`../../data/categories/categories_icons`);
    return categories[iconName] ?? categories['multi_category'];
  } catch (err) {
    return undefined;
  }
};

const tryCategoryRequire = async (categoryName, country_id) => {
  const filteredName = categoryName
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/\s/g, "_");

  const {categories} = await import(`../../data/categories/categories_${country_id}`);
  if (categories) {
    let result = 
      categories.find((categoryData) => categoryData.category_name === filteredName)
      ?? categories.find((categoryData) => categoryData.category_name === "*")
    return result;
  }
}

export const HorizontalCategoryList = () => {
  const { categories } = useSelector((state) => state.categories);
  const classes = useStyles();

  return (
    <div className={classes.scrollMenu}>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {categories.map((category, index) => (
          <HorizontalCategory category={category} key={index} title={index} itemId={index}/>
        ))}
      </ScrollMenu>
    </div>
  );
};

export const HorizontalCategory = ({ category }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accountProvider } = useSelector((state) => state.account);
  const categoriesState = useSelector((state) => state.categories);

  const [anchorEl, setAnchorEl] = useState(null);
  const [categoryData, setCaregoryData] = useState(undefined);
  const [imageLogo, setImageLogo] = useState(undefined);
  const [selected, setSelected] = useState(categoriesState.selectedCategories[category.name]);

  useEffect(() => {
    setSelected(categoriesState.selectedCategories[category.name]);
  }, [categoriesState.selectedCategories]);

  const title = category.name;
  let addSubmenu = true;
  let subCategoryId = 0;

  if (category.subcategories.length === 1 && category.subcategories[0].name.trim() === title.trim()) {
    addSubmenu = false;
    subCategoryId = category.subcategories[0].id;
  }

  tryCategoryRequire(title, accountProvider.country_id).then((currentCategoryData) => {
    setCaregoryData(currentCategoryData);
  });

  tryImagePathRequire(title).then((imageComponent) => {
    setImageLogo(imageComponent);
  });

  function onClickSubcategory(id) {
    return async () => {
      dispatch(setSearchFilter(""));
      await dispatch(toggleSelectedOption({ id: id, countryId: accountProvider?.country_id }));
      let updatedSubcategories = updateSelectedSubcategories(categoriesState, id);
      await dispatch(setListName({ listName: `${GTM_LIST_NAME_CHILD_CATEGORY} - ${title}`}));
      await dispatch(
        filterProducts((product) => {
          return updatedSubcategories.some((cat) => EcommerceProduct.filterByCategory(product, cat));
        })
      );

      if (window.location.pathname !== "/products") navigate(ProductsRelativeLink());
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }

  const handleClick = (event) => {
    if (addSubmenu) {
      dispatch(setSearchFilter(""));
      dispatch(toggleSelectedCategory({ name: title, countryId: accountProvider.country_id }));
      setAnchorEl(event.currentTarget);
      setSelected(true);
    }
    else {
      onClickSubcategory(subCategoryId)();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelected(false);
  };

  const renderHorizontalSubcategory = (sub) => {
    return (onClick) => {
      return (
        <MenuItem onClick={onClick} className={classes.listSubcategories}>
          <Typography variant="caption">{sub.name}</Typography>
        </MenuItem>
      )
    }
  };

  const HorizontalSubCategory = (sub) => {
    return sub.is_alcoholic === true ? (
      <AlcoholicValidationComponent
        render={renderHorizontalSubcategory(sub)}
        validationPassedCallback={onClickSubcategory(sub.id)}
      />
    ) : (
      renderHorizontalSubcategory(sub)(onClickSubcategory(sub.id))
    );
  }

  return (
    <div
      className={classes.listCategories}
      tabIndex={0}
    >
      <div key={title}>
        <Typography
          className={classes.categoryTitle}
          variant="body2"
          style={{
            borderColor: selected ? "#e02020" : "#0000001f",
            color: selected ? "#e02020" : "#000000",
          }}
          ariaControls={"menu-" + title}
          ariaHaspopup="true"
          onClick={handleClick}
        >
          {imageLogo?.render({
            className: classes.categoryIcon,
            fill: selected ? "#e02020" : undefined,
            stroke: selected ? "#e02020" : undefined
          })}
          {title}
        </Typography>
        <Menu
          id={"menu-" + title}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
          elevation={0}
          getContentAnchorEl={null}
          className={classes.subcategoriesMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
          PaperProps={{
            style: {
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            },
          }}
        >
          {addSubmenu &&
            categoriesState.categories
              .find((cat) => cat.id === category.id)
              ?.subcategories.map((sub) =>
                HorizontalSubCategory(sub)
              )}
        </Menu>
      </div>
      <div
        style={{
          height: "0px",
        }}
      />
    </div>
  );
};

function LeftArrow() {
  const classes = useStyles();
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);
  
  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()} placement={{left: "8px"}}>
      <img src={lefttArrow} alt="location" />
    </Arrow>
  );
}

function RightArrow() {
  const classes = useStyles();
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);
  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()} placement={{right: "8px"}}>
      <img src={rightArrow} alt="location" />
    </Arrow>
  );
}

function Arrow({ children, disabled, onClick, placement }) {
  /*Disabled temporary if the arrows are not needed*/
  const classes = useStyles();
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classes.arrow}
      style={{
        bottom: "16px",
        opacity: disabled ? "0" : "1",
        ...placement,
      }}
    >
      {children}
    </button>
  );
}
