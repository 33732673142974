import React from 'react';
import { Container, CssBaseline, Grid } from '@material-ui/core';
import { AddressFormMx } from 'features/main/AddressForms/AddressFormMx';
import { AddressFormGt } from 'features/main/AddressForms/AddressFormGt';
import { Navbar } from 'features/main/Navbar';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import { AddressFormCo } from 'features/main/AddressForms/AddressFormCo';

export const AddressFormPage = () => {
	const { accountProvider } = useSelector((state: RootState) => state.account);
	
  return (
    <>
			<CssBaseline/>
			<Navbar/>
			<main>
				<Container>
					<Grid>
						{accountProvider?.country_id === 'GT' && <AddressFormGt/>}
						{accountProvider?.country_id === 'CO' && <AddressFormCo/>}
						{accountProvider?.country_id === 'MX' && <AddressFormMx/>}
					</Grid>
				</Container>
			</main>
		</>
  )
};
