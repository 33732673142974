import { Grid } from "@material-ui/core";
import MobileStepper from "@material-ui/core/MobileStepper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppDispatch, RootState } from "app/store";
import { toggleParentCategory, updateSelectedSubcategoriesByParent } from "features/common/categorySlice";
import { GTM_LIST_NAME_PARENT_CATEGORY } from "features/common/gtmEventHandler";
import { filterProducts, setListName } from "features/common/productSlice";
import { ProductsPageLink } from "features/common/urlBuilder";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface Step {
  label: string;
  imgPath: string;
  bottler: string;
  action: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "700px",
    padding: "0px 16px",
    margin: "auto",
    paddingTop: "5px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "10px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    overflow: "hidden",
    width: "100%",
    borderRadius: "16px",
    cursor: "pointer",
  },
}));

export function PromotionCarousel() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(0);
  const dispatch: AppDispatch = useDispatch();
  const { accountProvider } = useSelector((state: RootState) => state.account);
  const categoriesState = useSelector((state: RootState) => state.categories);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const categoryRedirectionHandler = async () => {
    const warehouseCode: string = deliveryData.warehouse_code;
    const bottlers: Bottler[] = JSON.parse(accountProvider?.bottlers ?? "[]");
    const categoryName = bottlers.find((bottler) => bottler.warehouse_code === warehouseCode)?.combo_name ?? "";
    await dispatch(
      toggleParentCategory({
        name: categoryName,
        countryId: accountProvider?.country_id,
        currency: accountProvider?.currency,
      })
    );
    const updatedSubcategories: number[] = updateSelectedSubcategoriesByParent(categoriesState, categoryName);
    await dispatch(setListName({ listName: `${GTM_LIST_NAME_PARENT_CATEGORY} - ${categoryName}` }));
    await dispatch(
      filterProducts((product) => {
        return updatedSubcategories.some((cat) => product.categories?.includes(cat.toString()));
      })
    );

    if (window.location.pathname !== "/products") {
      window.location.assign(ProductsPageLink());
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Temporary solution while we get an implementation from Magento
  // Use ([] as Step[]) to avoid TS error while the array is empty
  // TODO: Remove this when we get the implementation from Magento
  const steps: Step[] = ([] as Step[]).filter((step) => step.bottler === deliveryData?.bottler);
  const maxSteps = steps.length;

  return maxSteps > 0 ? (
    <Grid className={classes.root}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
      >
        {steps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.imgPath} alt={step.label} onClick={step.action} id={step.label} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {maxSteps > 1 && (
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={<div></div>}
          backButton={<div></div>}
        />
      )}
    </Grid>
  ) : null;
}
