import { Button, createStyles, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import { setRegisterAccount } from "features/common/accountSlice";
import { funcAccountAddressPost, funcRegisterGetByPhone } from "features/common/API";
import { setDeliveryData } from "features/common/deliverySlice";
import { GeofencingPageLink, ProductsPageLink } from "features/common/urlBuilder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextErrorIcon from "../../../assets/icons/ui/textInputErrorIcon.svg";
import regions_CO, { streetTypes } from "../../../data/regions/regions_CO";
import { FullPageLoader, LoaderType } from "../FullPageLoader";
import { MessageDialog } from "../MessageDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: "100%",
      fontWeight: 500,
    },
    inputGrid: {
      marginTop: theme.spacing(2),
    },
    inputGridSeparator: {
      marginTop: theme.spacing(4),
    },
    textField: {
      width: "100%",
    },
    textFieldLabelRoot: {
      ...inputClasses.textFieldLabel.root,
    },
    textFieldLabelFocus: {
      ...inputClasses.textFieldLabel.focus,
    },
    textFieldLabelError: {
      ...inputClasses.textFieldLabel.error,
    },
    textFieldInputRoot: {
      ...inputClasses.textFieldInput.root,
    },
    textFieldInputOutlined: {
      ...inputClasses.textFieldInput.notchedOutline,
    },
    textFieldInputFocus: {
      ...inputClasses.textFieldInput.focus,
    },
    textFieldInputError: {
      ...inputClasses.textFieldInput.error,
    },
    textFieldFilled: {
      width: "100%",
    },
    disabledTextFieldLabelRoot: {
      ...inputClasses.disabledTextFieldLabel.root,
    },
    disabledTextFieldLabelFocus: {
      ...inputClasses.disabledTextFieldLabel.focus,
    },
    disabledTextFieldLabelError: {
      ...inputClasses.disabledTextFieldLabel.error,
    },
    disabledTextFieldInputRoot: {
      ...inputClasses.disabledTextFieldInput.root,
    },
    disabledTextFieldInputOutlined: {
      ...inputClasses.disabledTextFieldInput.notchedOutline,
    },
    disabledTextFieldInputFocus: {
      ...inputClasses.disabledTextFieldInput.focus,
    },
    disabledTextFieldInputError: {
      ...inputClasses.disabledTextFieldInput.error,
    },
    mobileStylePrimary: {
      ...inputClasses.mobileStylePrimary,
    },
    iconImage: {
      height: "16px",
      width: "16px",
      marginRight: "4px",
      marginLeft: "4px",
      alignItems: "center",
    },
  })
);

const helperTextStyles = makeStyles((theme) => ({
  ...inputClasses.textFieldHelperText,
}));

/*
  Summary:
    This method tries to decompose the street into a streetType and streetName for a given list of streetTypes for the autocomplete.
  Example:
    decomposeStreet(streetTypes, "Avenida Colombia") => returns {streetType: "Avenida", streetName: "Colombia" };
*/
const decomposeStreet = (streetTypes: string[], street: string): { streetType: string; streetName: string } => {
  var result = { streetType: streetTypes[0], streetName: "" };
  var sortedStreetTypes = [...streetTypes].sort((a: string, b: string) => a.length - b.length);
  if (street !== undefined) {
    var correctStreetType: string | undefined = sortedStreetTypes.find((streetType) => street.startsWith(streetType));
    if (correctStreetType) {
      result.streetType = correctStreetType;
      result.streetName = street.split(correctStreetType).slice(-1)[0].trimLeft();
    }
  }

  return result;
};

export const AddressFormCo = () => {
  const classes = useStyles();
  const helperTextClasses = helperTextStyles();
  const navigate = useNavigate();

  // Loader State
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const { deliveryData } = useSelector(
    (state: RootState) => state.deliveryData
  );
  const { conversationContext, registerAccount, accountProvider } = useSelector((state: RootState) => state.account);
  const { availabilityZone } = useSelector((state: RootState) => state.geofencing);
  const dispatch: AppDispatch = useDispatch();

  var initalPhoneNumber: string =
    conversationContext?.cellPhoneNumber.startsWith(accountProvider?.phone_digits_prefix as string) &&
    conversationContext?.cellPhoneNumber.length ===
      (accountProvider?.phone_digits_prefix.length as number) + (accountProvider?.phone_digits_quantity as number)
      ? (conversationContext?.cellPhoneNumber.slice(-1 * (accountProvider?.phone_digits_quantity as number)) as string)
      : "";

  var initialFirstName: string = registerAccount?.name.split(" ")[0] || "";
  var initialLastName: string = registerAccount?.name.split(" ").slice(1).join(" ") || "";

  // Load form fields
  const [region, setRegion] = useState(
    regions_CO.find(
      (regionCo) =>
        regionCo.region === availabilityZone?.address.region ||
        (regionCo.region === "Cundinamarca" && availabilityZone?.address.region === "Bogotá")
    )?.region || ""
  );
  const [neighborhood, setNeighborhood] = useState(availabilityZone?.address.neighborhood ?? "");
  const [number_int, setNumberInt] = useState("");
  const [postal_code, setPostalCode] = useState(availabilityZone?.address.postal_code ?? "-");
  const [city, setCity] = useState(availabilityZone?.address.city ?? "");

  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [phone, setPhone] = useState(initalPhoneNumber);

  const decomposedStreet = decomposeStreet(streetTypes, availabilityZone?.address.street as string);

  // Street decompose
  const [streetType, setStreetType] = useState(decomposedStreet.streetType);
  const [streetName, setStreetName] = useState(decomposedStreet.streetName);
  const [streetNumber2, setStreetNumber2] = useState(availabilityZone?.address.number ?? "");
  const [streetNumber3, setStreetNumber3] = useState("");

  // Load form errors
  const [cityError, setCityError] = useState("");
  const [neighborhoodError, setNeighborhoodError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [streetTypeError, setStreetTypeError] = useState("");
  const [streetNameError, setStreetNameError] = useState("");
  const [streetNumber2Error, setStreetNumber2Error] = useState("");

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  useEffect(() => {
    if (!deliveryData.warehouse_code || !availabilityZone) {
      setIsErrorMessageOpen(true);
      setErrorMessage("Lo siento 😅, tu sesión ha expirado, presiona regresar para continuar con el flujo de compra.");
      setErrorMessageButtonLink(GeofencingPageLink());
      setErrorMessageButtonText("Regresar");
      return;
    }

    (async () => {
      if (!registerAccount) {
        var registerAccount = await funcRegisterGetByPhone(
          conversationContext?.accountProviderId as number,
          conversationContext?.cellPhoneNumber as string
        );
        dispatch(setRegisterAccount(registerAccount as RegisterAccount));

        var initialFirstName: string = registerAccount?.name.split(" ")[0] || "";
        var initialLastName: string = registerAccount?.name.split(" ").slice(1).join(" ") || "";

        setFirstName(initialFirstName);
        setLastName(initialLastName);
      }
    })();
  }, []);

  const validateForm = () => {
    streetType === "" ? setStreetTypeError("Este campo es obligatorio.") : setStreetTypeError("");
    streetName === "" ? setStreetNameError("Este campo es obligatorio.") : setStreetNameError("");
    streetNumber2 === "" ? setStreetNumber2Error("Este campo es obligatorio.") : setStreetNumber2Error("");

    region === "" ? setRegionError("Este campo es obligatorio.") : setRegionError("");
    city === "" ? setCityError("Este campo es obligatorio.") : setCityError("");

    firstName === "" ? setFirstNameError("Este campo es obligatorio.") : setFirstNameError("");
    lastName === "" ? setLastNameError("Este campo es obligatorio.") : setLastNameError("");

    var isValidNeighborhood: boolean = true;
    if (neighborhood === "") {
      setNeighborhoodError("Este campo es obligatorio.");
      isValidNeighborhood = false;
    } else if (neighborhood == "0") {
      setNeighborhoodError("Ingresa un valor válido.");
      isValidNeighborhood = false;
    } else {
      setNeighborhoodError("");
    }

    var isValidPostalCode: boolean = true;
    if (postal_code === "") {
      setPostalCodeError('Este campo es obligatorio. Ingrese "-" si no conoce su código postal.');
      isValidPostalCode = false;
    } else if (!postal_code?.match("^[- A-Za-z0-9]+$")) {
      setPostalCodeError("El código postal solo puede contener letras, números, espacios o guión medio.");
      isValidPostalCode = false;
    } else {
      setPostalCodeError("");
    }

    var isValidPhoneNumber: boolean = true;
    if (phone === "") {
      setPhoneError("Campo obligatorio");
      isValidPhoneNumber = false;
    } else if (phone.length !== accountProvider?.phone_digits_quantity) {
      setPhoneError(`El número de teléfono debe tener ${accountProvider?.phone_digits_quantity} dígitos.`);
      isValidPhoneNumber = false;
    } else if (!/^\d+$/.test(phone)) {
      setPhoneError(`El número de teléfono solo puede tener dígitos.`);
      isValidPhoneNumber = false;
    } else {
      setPhoneError("");
    }

    return [
      streetType,
      streetName,
      streetNumber2,
      isValidNeighborhood,
      city,
      isValidPostalCode,
      isValidPhoneNumber,
      region,
    ].every((requiredField) => requiredField);
  };

  const onSendClick = async () => {
    if (validateForm()) {
      setIsLoaderActive(true);
      window.onbeforeunload = () => true;
      // Set values that the user put in the input form
      deliveryData.internal_number = number_int || "-";
      deliveryData.neighborhood = neighborhood as string;
      deliveryData.postal_code = postal_code as string;
      deliveryData.street = `${streetType} ${streetName}`;
      deliveryData.name = `${firstName} ${lastName}`;
      deliveryData.phone = phone;

      // Fix problem with some address that dont have region_id and region_code by getting the value using a hardcoded map with all the values for the universe of regions on the country.
      if (!deliveryData.region_id || !deliveryData.region_code) {
        deliveryData.region_id = regions_CO
          .find((regionCO) => regionCO.region === region)
          ?.region_id.toString() as string;
        deliveryData.region_code = regions_CO
          .find((regionCO) => regionCO.region === region)
          ?.region_code.toString() as string;
        deliveryData.region = region;
      }

      deliveryData.region_id = deliveryData.region_id.toString();

      // Set empty placeholder values for the field not used
      deliveryData.references = "references";
      deliveryData.municipality = "municipality";
      deliveryData.number = streetNumber3 === "" ? `#${streetNumber2}` : `#${streetNumber2}-${streetNumber3}`;

      var response = await funcAccountAddressPost(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        deliveryData
      );

      // If the address was saved on db corretly mark that the deliveryData saved on local memory is not longer a new address.
      if (response) {
        deliveryData.is_new = false;
      }
      await dispatch(setDeliveryData(deliveryData));

      if ("data" in response) {
        const errorMessage = (response as ErrorRequestResponse).data.error.toString();
        if (errorMessage.includes(" is not allowed to be empty")) {
          const fields: { [key: string]: string } = {
            street: "Vía y Nombre",
            number: "Número",
            postal_code: "Código Postal",
            name: "Nombre y/o Apellido",
            phone: "Teléfono",
            region: "Departamento",
            neighborhood: "Barrio",
            municipality: "Municipio",
            city: "Ciudad",
          };
          const field = errorMessage.slice(0, errorMessage.indexOf(" is not allowed"));

          setIsErrorMessageOpen(true);
          setErrorMessage(`El campo ${fields[field]} no puede ser vacío.`);
          setErrorMessageButtonLink("");
          setErrorMessageButtonText("Ok");

          return;
        }
      }

      // Go to cart to confirmOrder
      setIsLoaderActive(false);
      window.onbeforeunload = () => undefined;
      window.location.replace(ProductsPageLink());
    }
  };

  let addressForm = (
    <>
      <Typography variant="h1" className={classes.title}>
        Dirección de Entrega
      </Typography>
      <Grid className={classes.inputGrid} item>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: firstNameError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          value={firstName}
          error={firstNameError !== ""}
          helperText={firstNameError}
          label="Nombre"
          variant="outlined"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid} item>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: lastNameError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          value={lastName}
          error={lastNameError !== ""}
          helperText={lastNameError}
          label="Apellido"
          variant="outlined"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: phoneError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          required
          defaultValue={phone}
          error={phoneError !== ""}
          helperText={phoneError}
          label="Teléfono"
          variant="outlined"
          onChange={(e) => setPhone(e.target.value)}
        />
      </Grid>
      <Grid className={classes.inputGridSeparator} container spacing={2}>
        <Grid className={classes.inputGrid} item xs={6}>
          <TextField
            select
            className={classes.textFieldFilled}
            InputLabelProps={{
              classes: {
                root: classes.textFieldLabelRoot,
                focused: classes.textFieldLabelFocus,
                error: classes.textFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.textFieldInputRoot,
                focused: classes.textFieldInputFocus,
                notchedOutline: classes.textFieldInputOutlined,
                error: classes.textFieldInputError,
              },
            }}
            SelectProps={{ native: true }}
            required
            label="Vía"
            defaultValue={streetType}
            error={streetTypeError !== ""}
            helperText={streetTypeError}
            onChange={(e) => setStreetType(e.target.value)}
            variant="outlined"
          >
            {streetTypes.map((streetType) => (
              <option key={streetType} value={streetType}>
                {streetType}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid className={classes.inputGrid} item xs={6} spacing={0}>
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.textFieldLabelRoot,
                focused: classes.textFieldLabelFocus,
                error: classes.textFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.textFieldInputRoot,
                focused: classes.textFieldInputFocus,
                notchedOutline: classes.textFieldInputOutlined,
                error: classes.textFieldInputError,
              },
            }}
            className={classes.textFieldFilled}
            required
            label="Barrio"
            defaultValue={neighborhood}
            error={neighborhoodError !== ""}
            helperText={neighborhoodError}
            onChange={(e) => setNeighborhood(e.target.value)}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid className={classes.inputGrid} container xs={12} spacing={2}>
        <Grid className={classes.inputGrid} item xs={3} spacing={0}>
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.textFieldLabelRoot,
                focused: classes.textFieldLabelFocus,
                error: classes.textFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.textFieldInputRoot,
                focused: classes.textFieldInputFocus,
                notchedOutline: classes.textFieldInputOutlined,
                error: classes.textFieldInputError,
              },
            }}
            className={classes.textFieldFilled}
            required
            defaultValue={streetName}
            error={streetNameError !== ""}
            helperText={streetNameError}
            onChange={(e) => setStreetName(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.inputGrid} item xs={1} spacing={0}>
          <TextField className={classes.textFieldFilled} disabled label="#" />
        </Grid>
        <Grid className={classes.inputGrid} item xs={3} spacing={0}>
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.textFieldLabelRoot,
                focused: classes.textFieldLabelFocus,
                error: classes.textFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.textFieldInputRoot,
                focused: classes.textFieldInputFocus,
                notchedOutline: classes.textFieldInputOutlined,
                error: classes.textFieldInputError,
              },
            }}
            className={classes.textFieldFilled}
            required
            //label="No. 2"
            defaultValue={streetNumber2}
            error={streetNumber2Error !== ""}
            helperText={streetNumber2Error}
            onChange={(e) => setStreetNumber2(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.inputGrid} item xs={1} spacing={0}>
          <TextField className={classes.textFieldFilled} disabled label="-" />
        </Grid>
        <Grid className={classes.inputGrid} item xs={3} spacing={0}>
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.textFieldLabelRoot,
                focused: classes.textFieldLabelFocus,
                error: classes.textFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.textFieldInputRoot,
                focused: classes.textFieldInputFocus,
                notchedOutline: classes.textFieldInputOutlined,
                error: classes.textFieldInputError,
              },
            }}
            className={classes.textFieldFilled}
            defaultValue={streetNumber3}
            onChange={(e) => setStreetNumber3(e.target.value)}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
          }}
          className={classes.textFieldFilled}
          label="Complemento (opcional)"
          defaultValue={number_int}
          onChange={(e) => setNumberInt(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabelRoot,
              focused: classes.textFieldLabelFocus,
              error: classes.textFieldLabelError,
            },
          }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          InputProps={{
            classes: {
              root: classes.textFieldInputRoot,
              focused: classes.textFieldInputFocus,
              notchedOutline: classes.textFieldInputOutlined,
              error: classes.textFieldInputError,
            },
            endAdornment: postalCodeError !== "" ? <img className={classes.iconImage} src={TextErrorIcon} /> : null,
          }}
          className={classes.textFieldFilled}
          required
          label="Código Postal"
          defaultValue={postal_code}
          error={postalCodeError !== ""}
          helperText={postalCodeError}
          onChange={(e) => setPostalCode(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.inputGrid}>
        {region && (
          <TextField
            className={classes.textFieldFilled}
            InputLabelProps={{
              classes: {
                root: classes.disabledTextFieldLabelRoot,
                focused: classes.disabledTextFieldLabelFocus,
                error: classes.disabledTextFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.disabledTextFieldInputRoot,
                focused: classes.disabledTextFieldInputFocus,
                notchedOutline: classes.disabledTextFieldInputOutlined,
                error: classes.disabledTextFieldInputError,
              },
            }}
            required
            disabled
            label="Departamento"
            defaultValue={region}
            variant="outlined"
          />
        )}
        {!region && (
          <TextField
            select
            className={classes.textFieldFilled}
            InputLabelProps={{
              classes: {
                root: classes.textFieldLabelRoot,
                focused: classes.textFieldLabelFocus,
                error: classes.textFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.textFieldInputRoot,
                focused: classes.textFieldInputFocus,
                notchedOutline: classes.textFieldInputOutlined,
                error: classes.textFieldInputError,
              },
            }}
            SelectProps={{ native: true }}
            required
            label="Departamento"
            defaultValue={region}
            error={regionError !== ""}
            helperText={regionError}
            onChange={(e) => setRegion(e.target.value)}
            variant="outlined"
          >
            {regions_CO.map((regionCO) => (
              <option key={regionCO.region} value={regionCO.region}>
                {regionCO.region}
              </option>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid className={classes.inputGrid}>
        {!(availabilityZone?.address.city && availabilityZone?.address.city !== "undefined") && (
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.textFieldLabelRoot,
                focused: classes.textFieldLabelFocus,
                error: classes.textFieldLabelError,
              },
            }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            InputProps={{
              classes: {
                root: classes.textFieldInputRoot,
                focused: classes.textFieldInputFocus,
                notchedOutline: classes.textFieldInputOutlined,
                error: classes.textFieldInputError,
              },
            }}
            className={classes.textFieldFilled}
            required
            label="Ciudad"
            defaultValue={city}
            error={cityError !== ""}
            helperText={cityError}
            onChange={(e) => setCity(e.target.value)}
            variant="outlined"
          />
        )}
        {availabilityZone?.address.city && availabilityZone?.address.city !== "undefined" && (
          <TextField
            className={classes.textFieldFilled}
            InputLabelProps={{
              classes: {
                root: classes.disabledTextFieldLabelRoot,
                focused: classes.disabledTextFieldLabelFocus,
                error: classes.disabledTextFieldLabelError,
              },
            }}
            FormHelperTextProps={{ style: { color: "#EF350C" } }}
            InputProps={{
              classes: {
                root: classes.disabledTextFieldInputRoot,
                focused: classes.disabledTextFieldInputFocus,
                notchedOutline: classes.disabledTextFieldInputOutlined,
                error: classes.disabledTextFieldInputError,
              },
            }}
            required
            disabled
            label="Ciudad"
            defaultValue={availabilityZone?.address.city}
            variant="outlined"
          />
        )}
      </Grid>
      <Grid className={classes.inputGrid}>
        <Button
          disabled={isLoaderActive}
          className={classes.mobileStylePrimary}
          color="primary"
          onClick={() => onSendClick()}
        >
          Proceder al Pago
        </Button>
      </Grid>
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  );

  return isLoaderActive ? (
    <FullPageLoader
      type={LoaderType.Authentication}
      text="Procesando direccion..."
    />
  ) : (
    addressForm
  );
};
