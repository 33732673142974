import React from 'react';
import { Main } from "../main/Main";
import { CssBaseline } from "@material-ui/core";

export const ProductsPage = () => {
  return (
    <>
      <CssBaseline />
      <Main />
    </>
  )
};
