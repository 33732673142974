import { Card, makeStyles, Typography } from "@material-ui/core";
import { RootState } from "app/store";
import { formatNumber } from "features/common/formatHelpers";
import { useBottomScroll } from "features/hooks/useBottomScroll";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    height: 44,
    position: "fixed",
    bottom: "8px",
    left: "16px",
    right: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
  },
}));

export const CartTotal = () => {
  const classes = useStyles();

  const { subtotal, shipping } = useSelector((state: RootState) => state.products);
  const { accountProvider } = useSelector((state: RootState) => state.account);

  const [enabled, setEnabled] = useState(
    subtotal + shipping >= (accountProvider?.minimum_order_amount || 0) ? true : false
  );
  const isBottomScroll = useBottomScroll();

  useEffect(() => {
    setEnabled(subtotal + shipping >= (accountProvider?.minimum_order_amount || 0) ? true : false);
  }, [subtotal, shipping]);

  const goCart = () => {
    if (enabled) {
      const cartContainer = document.getElementById("cart-container");
      const offset = 200;
      const containerPosition = cartContainer?.getBoundingClientRect().top;
      var offsetPosition = containerPosition! + window.scrollY - offset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  return (
    <Card
      className={classes.root}
      id="totalScrollDownButton"
      style={{
        backgroundColor: enabled ? "#000" : "Gray",
        color: enabled ? "White" : "#000000",
        display: isBottomScroll ? "none" : "flex",
      }}
      onClick={goCart}
      variant="outlined"
    >
      <div>
        <Typography variant="body1">
          Total: {accountProvider?.currency_symbol}
          {formatNumber(subtotal + shipping, accountProvider?.amount_format, accountProvider?.amount_separator)}
        </Typography>
      </div>
    </Card>
  );
};
