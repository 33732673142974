import { Button, Card, CardActionArea, CardMedia, makeStyles } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { toggleParentCategory, updateSelectedSubcategoriesByParent } from "features/common/categorySlice";
import { GTM_LIST_NAME_PARENT_CATEGORY } from "features/common/gtmEventHandler";
import { filterProducts, setListName } from "features/common/productSlice";
import { ProductsRelativeLink } from "features/common/urlBuilder";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlcoholicValidationComponent } from "./AlcoholicValidationDialog";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "8px 0px 8px 16px",
    position: "relative",
    boxShadow: "none",
  },
  image: {
    borderRadius: "16px",
    maxWidth: "200px",
    maxHeight: "200px",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "146px",
      width: "100%",
    },
    objectFit: "inherit",
  },
  buttonCategory: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    maxWidth: "200px",
    margin: "auto",
    color: "white",
    backgroundColor: "#000000",
    height: "36px",
    paddingLeft: "0px",
    paddingRight: "0px",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "unset",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0em",
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const tryImagePathRequire = (categoryName: string, country_id: string) => {
  const imgName = categoryName
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/\s/g, "_");

  try {
    return require(`../../assets/categories/${country_id}/${imgName}.jpg`) as string;
  } catch (err) {
    try {
      return require(`../../assets/categories/${country_id}/${imgName}.png`) as string;
    } catch (err) {
      return require(`../../assets/categories/generica01.png`) as string;
    }
  }
};

const tryCategoryRequire = async (categoryName: string, country_id: string) => {
  const filteredName = categoryName
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/\s/g, "_");

  const { categories } = await import(`../../data/categories/categories_${country_id}`);
  if (categories) {
    let result =
      categories.find((categoryData: { category_name: string }) => categoryData.category_name === filteredName) ??
      categories.find((categoryData: { category_name: string }) => categoryData.category_name === "*");
    return result;
  }
};

export const CategoryItem = ({ category }: { category: EcommerceCategories }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { accountProvider } = useSelector((state: RootState) => state.account);
  const dispatch: AppDispatch = useDispatch();
  let categoriesState = useSelector((state: RootState) => state.categories);
  const [categoryData, setCaregoryData] = useState<CategoryData | undefined>(undefined);
  const [isOnFocus, setIsOnFocus] = useState(false);

  tryCategoryRequire(category.group, accountProvider?.country_id || "").then((currentCategoryData) => {
    setCaregoryData(currentCategoryData);
  });

  const onClickCategory = async () => {
    await dispatch(
      toggleParentCategory({
        name: category.group,
        countryId: accountProvider?.country_id,
        currency: accountProvider?.currency,
      })
    );
    const updatedSubcategories: number[] = updateSelectedSubcategoriesByParent(categoriesState, category.group);
    await dispatch(setListName({ listName: `${GTM_LIST_NAME_PARENT_CATEGORY} - ${category.group}` }));
    await dispatch(
      filterProducts((product) => {
        return updatedSubcategories.some((cat) => product.categories?.includes(cat.toString()));
      })
    );

    if (window.location.pathname !== "/products") {
      navigate(ProductsRelativeLink());
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderCategoryItem = (onClickEvent: () => void) => {
    return (
      <Card key={category.child_id} className={classes.card} onClick={onClickEvent}>
        <CardActionArea>
          <CardMedia
            component="img"
            image={tryImagePathRequire(category.group, accountProvider?.country_id || "")}
            title={category.group}
            className={classes.image}
          />
          <Button
            onClick={() => setIsOnFocus(true)}
            onMouseEnter={() => setIsOnFocus(true)}
            onMouseLeave={() => setIsOnFocus(false)}
            style={{
              backgroundColor: isOnFocus ? "#e02020" : "#000000",
            }}
            className={classes.buttonCategory}
            variant="contained"
          >
            {category.group}
          </Button>
        </CardActionArea>
      </Card>
    );
  };

  return category.is_alcoholic === true ? (
    <AlcoholicValidationComponent render={renderCategoryItem} validationPassedCallback={onClickCategory} />
  ) : (
    renderCategoryItem(onClickCategory)
  );
};
