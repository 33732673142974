import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inputClasses } from "app/tccc-theme";
import {
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles, Radio, RadioGroup, TextField, Typography,
} from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import regions_MX from "data/regions/regions_MX";
import { setBillingInformation, setSelectedPaymentMethod} from "features/common/deliverySlice";
import { DocumentsPageLink } from "features/common/urlBuilder";
import taxRegimeBusiness_MX from "data/taxRegime/taxRegimeBusiness_MX";
import taxRegimePersonal_MX from "data/taxRegime/taxRegimePersonal_MX";
import PaymentCardIcon from "../../assets/icons/ui/paymentCardIcon.svg";
import PaymentCashIcon from "../../assets/icons/ui/paymentCashIcon.svg";

const useStyles = makeStyles((theme) => ({
    paymentSection: {
      marginBottom: theme.spacing(2),
    },
    root: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: "#F0F0F0",
      maxWidth: "500px",
      margin: "auto",
    },
    textInfo: {
      textAlign: "left",
      fontWeight: 400,
      lineHeight: "16px",
    },
    textData: {
      textAlign: "left",
      fontWeight: 500,
    },
    billingAddress: {
      textAlign: "justify",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    billingAddressField: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    orderActions: {
      justifyContent: "center",
    },
    invoiceFormTextField: {
      marginBottom: theme.spacing(2),
    },
    invoiceFormRadioField: {
      marginTop: theme.spacing(1),
    },
    invoiceRadioGroup: {
      flexDirection: "row",
    },
    invoiceRadio: {
      ...inputClasses.radioButton,
    },
    invoiceCheckbox: {
      ...inputClasses.checkBox,
    },
    invoiceActionsFEMSA: {
      justifyContent: "center",
    },
    textFieldLabelRoot: {
      ...inputClasses.textFieldLabel.root,
    },
    textFieldLabelFocus: {
      ...inputClasses.textFieldLabel.focus,
    },
    textFieldLabelError: {
      ...inputClasses.textFieldLabel.error,
    },
    textFieldInputRoot: {
      ...inputClasses.textFieldInput.root,
    },
    textFieldInputOutlined: {
      ...inputClasses.textFieldInput.notchedOutline,
    },
    textFieldInputFocus: {
      ...inputClasses.textFieldInput.focus,
    },
    textFieldInputError: {
      ...inputClasses.textFieldInput.error,
    },
    mobileStylePrimary: {
      ...inputClasses.mobileStylePrimary,
    },
    textFieldRfcInput: {
      textTransform: "uppercase",
    },
    paymentCard: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      backgroundColor: "#FBFBFB",
      maxWidth: "500px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      borderRadius: "16px",
    },
    paymentMessage: {
      display: "flex",
      flexWrap: "initial",
    },
    paymentIconGrid: {
      alignItems: "center",
      display: "flex",
    },
    iconPaymentImage: {
      height: "21px",
      width: "21px",
      marginRight: "4px",
      marginLeft: "4px",
      alignItems: "center",
    },

    paymentOptions: {
    },
    paymentMELI: {
    },
    paymentCash: {
    },        
}));

const helperTextStyles = makeStyles((theme) => ({
    ...inputClasses.textFieldHelperText,
}));

export const CASH_ON_DELIVERY_PAYMENT_CODE = 'cashondelivery'; // TODO: Get this values dinamically from the CCETH APIs
export const MERCADO_PAGO_WRAPPER_PAYMENT_CODE = 'payment_wrapper';

export const Payment = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const helperTextClasses = helperTextStyles();

  const { deliveryData, selectedPaymentMethod } =
    useSelector((state: RootState) => state.deliveryData);
  const { cart } = useSelector((state: RootState) => state.products);
  const { accountProvider } = useSelector((state: RootState) => state.account);

  // Modal Controls
  const [isInvoiceFormOpen, setIsInvoiceFormOpen] = useState(false);

  // Invoice Form
  const [invoiceNameOrSocialReason, setInvoiceNameOrSocialReason] = useState("");
  const [invoiceRfc, setInvoiceRfc] = useState("");
  const [invoiceType, setInvoiceType] = useState<"personal" | "business">("personal");
  const [invoiceTaxRegime, setInvoiceTaxRegime] = useState("605");
  const [invoiceTaxCapitalRegime, setInvoiceTaxCapitalRegime] = useState("");
  const [invoiceCFDIUse, setInvoiceCFDIUse] = useState<"G01" | "G03">("G03");
  const [invoiceFormErrors, setInvoiceFormErrors] = useState({
    name: "",
    rfc: "",
    taxCapitalRegime: "",
    street: "",
    numExt: "",
    cp: "",
    state: "",
    neighborhood: "",
    municipality: "",
  });

  const [billingAddress, setBillingAddress] = useState(false);
  const [billingStreet, setBillingStreet] = useState("");
  const [billingExt, setBillingExt] = useState("");
  const [billingInt, seBillingInt] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingNeighborhood, setBillingNeighborhood] = useState("");
  const [billingMunicipality, setBillingMunicipality] = useState("");
  const [billingPc, setBillingPc] = useState("");

  var bottlers = JSON.parse(accountProvider?.bottlers || "[]");

  var bottlerShippingPolicyUrl = bottlers.find(
    (bottler: { bottler_name: string | undefined }) => bottler.bottler_name === deliveryData.bottler
  )?.shipping_policy_url;

  const bottlerPaymentMethod = bottlers.find(
    (bottler: { warehouse_code: string | undefined }) => bottler.warehouse_code === deliveryData.warehouse_code
  )?.payment_method;
  const defaultPaymentMethod = "El pago es contra entrega"; // TODO adjust, this is only activited for FEMSA Guatemala

  useEffect(() => {
    setInvoiceInformation();
  }, [dispatch]);

  const isBillingAddressForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBillingAddress(event.target.checked);
  };
  const handleStateChange = (event: any) => {
    setBillingState(event.target.value);
  };

  const validateInvoiceForm = () => {
    const errors = {
      name: "",
      rfc: "",
      taxCapitalRegime: "",
      street: "",
      numExt: "",
      cp: "",
      state: "",
      neighborhood: "",
      municipality: "",
    };

    if (invoiceNameOrSocialReason === "") errors.name = "Campo Requerido";
    if (!invoiceNameOrSocialReason) {
      errors.name = "Campo Requerido";
    } else {
      const reg = /^([a-z ñáéíóú .'"&]{2,60})$/i;
      if (!reg.test(invoiceNameOrSocialReason)) {
        errors.name = "El nombre contiene caracteres inválidos";
      }
    }

    if (!invoiceRfc) {
      errors.rfc = "Campo Requerido";
    } else if (!(invoiceRfc.length === 12 || invoiceRfc.length === 13)) {
      errors.rfc = "RFC debe ser entre 12 y 13 caracteres";
    } else {
      let valid = "";
      if (invoiceRfc.length === 12) {
        valid = "^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      } else {
        valid = "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      }

      const validRfc = new RegExp(valid);
      const matchArray = invoiceRfc.match(validRfc);
      if (matchArray == null) {
        errors.rfc = "El RFC contiene caracteres inválidos";
      }
    }

    if (invoiceType === "business" && invoiceTaxCapitalRegime === "")
      errors.taxCapitalRegime = "Campo Requerido, ingrese ejemplo:SOCIEDAD ANONIMA DE CAPITAL VARIABLE";

    if (billingAddress) {
      if (billingStreet === "") errors.street = "Campo Requerido";
      if (billingExt === "") errors.numExt = "Campo Requerido";
      if (billingPc === "") errors.cp = "Campo Requerido";
      if (billingState === "") errors.state = "Campo Requerido";
      if (billingNeighborhood === "") errors.neighborhood = "Campo Requerido";
      if (billingMunicipality === "") errors.municipality = "Campo Requerido";
    }

    setInvoiceFormErrors(errors);

    // If we have errors, return
    if (errors.name !== "" || errors.rfc !== "" || errors.taxCapitalRegime !== "") return;

    setInvoiceInformation();
    setIsInvoiceFormOpen(false);
  };

  const setInvoiceInformation = () => {
    const region = billingState ? billingState : "";
    const region_id = regions_MX.find((regionMX) => regionMX.region === region)?.region_id.toString();
    const region_code = regions_MX.find((regionMX) => regionMX.region === region)?.region_code;
    const billingStreetList = [billingStreet ? billingStreet : ""];

    const billingAddressData: BillingAddress = {
      street: billingStreetList ?? [],
      number: billingExt ?? "",
      number_int: billingInt ?? "",
      postcode: billingPc ?? "",
      neighborhood: billingNeighborhood ?? "",
      municipality: billingMunicipality ?? "",
      region: region ?? "",
      region_id: region_id ?? "",
      region_code: region_code ?? "",
      city: billingMunicipality ?? "",
    };
    const invoiceData: BillingInformation = {
      rfc: invoiceRfc ?? "",
      rfc_type: invoiceType ?? "",
      billing_name: invoiceNameOrSocialReason ?? "",
      tax_regime: invoiceTaxRegime ?? "",
      tax_cfdi_use: invoiceCFDIUse ?? "",
      tax_capital_regime: invoiceTaxCapitalRegime ?? "",
      want_bill: invoiceRfc !== null && invoiceRfc !== "" ? true : false,
      billingAddress: billingAddressData ?? {},
    };

    dispatch(setBillingInformation(invoiceData));
  };

  return (
    <div className={classes.paymentSection} id="paymentSectionId">

      {cart && Object.keys(cart).length !== 0 && (
        <Card className={classes.root}>
          <div>
            <Typography className={classes.textData}>
              Selecciona un método de pago
            </Typography>
            <br />
            <Grid className={classes.paymentOptions}>
              <Card 
                className={classes.paymentCard}
                onClick={() => dispatch(setSelectedPaymentMethod(MERCADO_PAGO_WRAPPER_PAYMENT_CODE))} // TODO make dinamic
                style={{ 
                  border: selectedPaymentMethod === MERCADO_PAGO_WRAPPER_PAYMENT_CODE ? "1px solid #e02020" : "1px solid #F2F2F2",
                }}
              >
                <Grid item container direction="row" className={classes.paymentMessage}>
                  <Grid item className={classes.paymentIconGrid}>
                    <img className={classes.iconPaymentImage} alt="logo" src={PaymentCardIcon} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textData} variant="h6">
                      Pagar con tarjeta de crédito
                    </Typography>
                    <Typography className={classes.textInfo} variant="h6">
                      Tu compra se concretará en Mercado Pago
                    </Typography>
                  </Grid>
                </Grid>    
              </Card>
              <Card 
                className={classes.paymentCard}
                onClick={() => dispatch(setSelectedPaymentMethod(CASH_ON_DELIVERY_PAYMENT_CODE))} // TODO make dinamic
                style={{ 
                  border: selectedPaymentMethod === CASH_ON_DELIVERY_PAYMENT_CODE ? "1px solid #e02020" : "1px solid white",
                }}
              >
                <Grid item container direction="row" className={classes.paymentMessage}>
                  <Grid item className={classes.paymentIconGrid}>
                    <img className={classes.iconPaymentImage} alt="logo" src={PaymentCashIcon} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textData} variant="h6">
                      Pagar con efectivo al momento de la entrega
                    </Typography>
                    <Typography className={classes.textInfo} variant="h6">
                      {bottlerPaymentMethod !== undefined && bottlerPaymentMethod !== null
                      ? "El pago es " + bottlerPaymentMethod
                      : defaultPaymentMethod}
                    </Typography>
                  </Grid>
                </Grid>
              </Card> 
            </Grid>

            {bottlerShippingPolicyUrl && (
              <>
                {deliveryData.bottler === "FEMSA" && (
                  <Typography className={classes.textInfo}>Si deseas factura, revisa nuestras</Typography>
                )}
                <Typography className={classes.textInfo}>
                  <a href={DocumentsPageLink()}>Políticas de envío</a>
                </Typography>
                {deliveryData.bottler !== "FEMSA" && (
                  <>
                    <Typography className={classes.textInfo}>
                      Si deseas factura, da click{" "}
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#2000FF",
                          fontSize: "inherit",
                          fontFamily: "TCCC-UnityHeadline",
                        }}
                        onClick={() => setIsInvoiceFormOpen(true)}
                      >
                        aquí
                      </button>
                    </Typography>
                    <Dialog open={isInvoiceFormOpen}>
                      <DialogTitle>Datos de Factura</DialogTitle>
                      <DialogContent>
                        <TextField
                          className={classes.invoiceFormTextField}
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabelRoot,
                              focused: classes.textFieldLabelFocus,
                              error: classes.textFieldLabelError,
                            },
                          }}
                          FormHelperTextProps={{ classes: helperTextClasses }}
                          InputProps={{
                            classes: {
                              root: classes.textFieldInputRoot,
                              focused: classes.textFieldInputFocus,
                              notchedOutline: classes.textFieldInputOutlined,
                              error: classes.textFieldInputError,
                            },
                          }}
                          autoFocus
                          fullWidth
                          label="Nombre o Razón Social"
                          value={invoiceNameOrSocialReason}
                          onChange={(e) => setInvoiceNameOrSocialReason(e.target.value)}
                          required
                          error={invoiceFormErrors.name !== ""}
                          helperText={invoiceFormErrors.name}
                          variant="outlined"
                        />
                        <TextField
                          className={classes.invoiceFormTextField}
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabelRoot,
                              focused: classes.textFieldLabelFocus,
                              error: classes.textFieldLabelError,
                            },
                          }}
                          FormHelperTextProps={{ classes: helperTextClasses }}
                          InputProps={{
                            classes: {
                              root: classes.textFieldInputRoot,
                              focused: classes.textFieldInputFocus,
                              notchedOutline: classes.textFieldInputOutlined,
                              error: classes.textFieldInputError,
                              input: classes.textFieldRfcInput,
                            },
                          }}
                          fullWidth
                          label="RFC"
                          value={invoiceRfc}
                          onChange={(e) => setInvoiceRfc(e.target.value.toUpperCase())}
                          required
                          error={invoiceFormErrors.rfc !== ""}
                          helperText={invoiceFormErrors.rfc}
                          variant="outlined"
                        />
                        <FormLabel className={classes.invoiceFormRadioField} component="legend" required>
                          Tipo de beneficiario
                        </FormLabel>
                        <RadioGroup
                          className={classes.invoiceRadioGroup}
                          name="Type"
                          onChange={(e) => setInvoiceType(e.target.value as "personal" | "business")}
                        >
                          <FormControlLabel
                            value="personal"
                            control={<Radio className={classes.invoiceRadio} />}
                            label={<Typography variant="caption">Persona Física</Typography>}
                            checked={invoiceType === "personal"}
                          />
                          <FormControlLabel
                            value="business"
                            control={<Radio className={classes.invoiceRadio} />}
                            label={<Typography variant="caption">Persona Moral</Typography>}
                            checked={invoiceType === "business"}
                          />
                        </RadioGroup>
                        <TextField
                          className={classes.invoiceFormTextField}
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabelRoot,
                              focused: classes.textFieldLabelFocus,
                              error: classes.textFieldLabelError,
                            },
                          }}
                          FormHelperTextProps={{ classes: helperTextClasses }}
                          InputProps={{
                            classes: {
                              root: classes.textFieldInputRoot,
                              focused: classes.textFieldInputFocus,
                              notchedOutline: classes.textFieldInputOutlined,
                              error: classes.textFieldInputError,
                            },
                          }}
                          select
                          label="Régimen Fiscal"
                          value={invoiceTaxRegime}
                          onChange={(e) => setInvoiceTaxRegime(e.target.value)}
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          {invoiceType === "personal" &&
                            taxRegimePersonal_MX.map(
                              (
                                tax_regime // TODO: make dinamic the use of this taxRegime by the countryId. For now the invoice flow is only used by Mex.
                              ) => (
                                <option key={tax_regime.tax_regime} value={tax_regime.tax_regime_code}>
                                  {tax_regime.tax_regime}
                                </option>
                              )
                            )}
                          {invoiceType === "business" &&
                            taxRegimeBusiness_MX.map(
                              (
                                tax_regime // TODO: make dinamic the use of this taxRegime by the countryId. For now the invoice flow is only used by Mex.
                              ) => (
                                <option key={tax_regime.tax_regime} value={tax_regime.tax_regime_code}>
                                  {tax_regime.tax_regime}
                                </option>
                              )
                            )}
                        </TextField>
                        <FormLabel className={classes.invoiceFormRadioField} component="legend" required>
                          Uso CFDI
                        </FormLabel>
                        <RadioGroup
                          className={classes.invoiceRadioGroup}
                          name="Type"
                          onChange={(e) => setInvoiceCFDIUse(e.target.value as "G01" | "G03")}
                        >
                          <FormControlLabel
                            value="G01"
                            control={<Radio className={classes.invoiceRadio} />}
                            label={<Typography variant="caption">Adquisición de Mercancías</Typography>}
                            checked={invoiceCFDIUse === "G01"}
                          />
                          <FormControlLabel
                            value="G03"
                            control={<Radio className={classes.invoiceRadio} />}
                            label={<Typography variant="caption">Gastos en General</Typography>}
                            checked={invoiceCFDIUse === "G03"}
                          />
                        </RadioGroup>
                        {invoiceType === "business" && (
                          <TextField
                            className={classes.invoiceFormTextField}
                            InputLabelProps={{
                              classes: {
                                root: classes.textFieldLabelRoot,
                                focused: classes.textFieldLabelFocus,
                                error: classes.textFieldLabelError,
                              },
                            }}
                            FormHelperTextProps={{ classes: helperTextClasses }}
                            InputProps={{
                              classes: {
                                root: classes.textFieldInputRoot,
                                focused: classes.textFieldInputFocus,
                                notchedOutline: classes.textFieldInputOutlined,
                                error: classes.textFieldInputError,
                              },
                            }}
                            fullWidth
                            label="Régimen Capital"
                            value={invoiceTaxCapitalRegime}
                            onChange={(e) => setInvoiceTaxCapitalRegime(e.target.value)}
                            required={invoiceType === "business"}
                            disabled={invoiceType !== "business"}
                            error={invoiceFormErrors.taxCapitalRegime !== ""}
                            helperText={invoiceFormErrors.taxCapitalRegime}
                            variant="outlined"
                          />
                        )}
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.invoiceCheckbox}
                              checked={billingAddress}
                              onChange={isBillingAddressForm}
                            />
                          }
                          label={
                            <Typography variant="caption">
                              Dirección de facturación diferente a dirección de envío
                            </Typography>
                          }
                        />
                        {billingAddress && (
                          <Container fixed className={classes.billingAddress}>
                            <TextField
                              className={classes.billingAddressField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.textFieldLabelRoot,
                                  focused: classes.textFieldLabelFocus,
                                  error: classes.textFieldLabelError,
                                },
                              }}
                              FormHelperTextProps={{ classes: helperTextClasses }}
                              InputProps={{
                                classes: {
                                  root: classes.textFieldInputRoot,
                                  focused: classes.textFieldInputFocus,
                                  notchedOutline: classes.textFieldInputOutlined,
                                  error: classes.textFieldInputError,
                                },
                              }}
                              label="Calle"
                              value={billingStreet}
                              onChange={(e) => setBillingStreet(e.target.value)}
                              required
                              error={invoiceFormErrors.street !== ""}
                              helperText={invoiceFormErrors.street}
                              variant="outlined"
                            />
                            <TextField
                              className={classes.billingAddressField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.textFieldLabelRoot,
                                  focused: classes.textFieldLabelFocus,
                                  error: classes.textFieldLabelError,
                                },
                              }}
                              FormHelperTextProps={{ classes: helperTextClasses }}
                              InputProps={{
                                classes: {
                                  root: classes.textFieldInputRoot,
                                  focused: classes.textFieldInputFocus,
                                  notchedOutline: classes.textFieldInputOutlined,
                                  error: classes.textFieldInputError,
                                },
                              }}
                              label="Número exterior"
                              value={billingExt}
                              onChange={(e) => setBillingExt(e.target.value)}
                              required
                              error={invoiceFormErrors.numExt !== ""}
                              helperText={invoiceFormErrors.numExt}
                              variant="outlined"
                            />
                            <TextField
                              className={classes.billingAddressField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.textFieldLabelRoot,
                                  focused: classes.textFieldLabelFocus,
                                  error: classes.textFieldLabelError,
                                },
                              }}
                              FormHelperTextProps={{ classes: helperTextClasses }}
                              InputProps={{
                                classes: {
                                  root: classes.textFieldInputRoot,
                                  focused: classes.textFieldInputFocus,
                                  notchedOutline: classes.textFieldInputOutlined,
                                  error: classes.textFieldInputError,
                                },
                              }}
                              label="Número interior"
                              value={billingInt}
                              onChange={(e) => seBillingInt(e.target.value)}
                              variant="outlined"
                            />
                            <TextField
                              className={classes.billingAddressField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.textFieldLabelRoot,
                                  focused: classes.textFieldLabelFocus,
                                  error: classes.textFieldLabelError,
                                },
                              }}
                              FormHelperTextProps={{ classes: helperTextClasses }}
                              InputProps={{
                                classes: {
                                  root: classes.textFieldInputRoot,
                                  focused: classes.textFieldInputFocus,
                                  notchedOutline: classes.textFieldInputOutlined,
                                  error: classes.textFieldInputError,
                                },
                              }}
                              label="CP"
                              value={billingPc}
                              inputProps={{ maxLength: 5 }}
                              onChange={(e) => setBillingPc(e.target.value)}
                              required
                              error={invoiceFormErrors.cp !== ""}
                              helperText={invoiceFormErrors.cp}
                              variant="outlined"
                            />
                            <TextField
                              className={classes.billingAddressField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.textFieldLabelRoot,
                                  focused: classes.textFieldLabelFocus,
                                  error: classes.textFieldLabelError,
                                },
                              }}
                              FormHelperTextProps={{ classes: helperTextClasses }}
                              InputProps={{
                                classes: {
                                  root: classes.textFieldInputRoot,
                                  focused: classes.textFieldInputFocus,
                                  notchedOutline: classes.textFieldInputOutlined,
                                  error: classes.textFieldInputError,
                                },
                              }}
                              select
                              label="Estado"
                              value={billingState}
                              onChange={handleStateChange}
                              SelectProps={{
                                native: true,
                              }}
                              error={invoiceFormErrors.state !== ""}
                              helperText={invoiceFormErrors.state}
                              variant="outlined"
                            >
                              {regions_MX.map(
                                (
                                  region // TODO: make dinamic the use of this taxRegime by the countryId. For now the invoice flow is only used by Mex.
                                ) => (
                                  <option key={region.region} value={region.region}>
                                    {region.region}
                                  </option>
                                )
                              )}
                            </TextField>
                            <TextField
                              className={classes.billingAddressField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.textFieldLabelRoot,
                                  focused: classes.textFieldLabelFocus,
                                  error: classes.textFieldLabelError,
                                },
                              }}
                              FormHelperTextProps={{ classes: helperTextClasses }}
                              InputProps={{
                                classes: {
                                  root: classes.textFieldInputRoot,
                                  focused: classes.textFieldInputFocus,
                                  notchedOutline: classes.textFieldInputOutlined,
                                  error: classes.textFieldInputError,
                                },
                              }}
                              label="Municipio"
                              value={billingMunicipality}
                              onChange={(e) => setBillingMunicipality(e.target.value)}
                              required
                              error={invoiceFormErrors.municipality !== ""}
                              helperText={invoiceFormErrors.municipality}
                              variant="outlined"
                            />
                            <TextField
                              className={classes.billingAddressField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.textFieldLabelRoot,
                                  focused: classes.textFieldLabelFocus,
                                  error: classes.textFieldLabelError,
                                },
                              }}
                              FormHelperTextProps={{ classes: helperTextClasses }}
                              InputProps={{
                                classes: {
                                  root: classes.textFieldInputRoot,
                                  focused: classes.textFieldInputFocus,
                                  notchedOutline: classes.textFieldInputOutlined,
                                  error: classes.textFieldInputError,
                                },
                              }}
                              label="Colonia"
                              value={billingNeighborhood}
                              onChange={(e) => setBillingNeighborhood(e.target.value)}
                              required
                              error={invoiceFormErrors.neighborhood !== ""}
                              helperText={invoiceFormErrors.neighborhood}
                              variant="outlined"
                            />
                          </Container>
                        )}
                      </DialogContent>
                      <DialogActions className={classes.orderActions}>
                        <Button
                          className={classes.mobileStylePrimary}
                          color="primary"
                          onClick={() => validateInvoiceForm()}
                        >
                          Confirmar
                        </Button>
                        <Button color="secondary" onClick={() => setIsInvoiceFormOpen(false)}>
                          Cancelar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </>
            )}
          </div>
        </Card>
      )}  
    </div>
  );
};

