import { Card, Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { InfoOutlined as InfoIcon } from "@material-ui/icons";
import { RootState } from "app/store";
import { formatNumber } from "features/common/formatHelpers";
import { handleProductView } from "features/common/gtmEventHandler";
import { EcommerceProduct } from "models/ecommerceProduct";
import { ReturnableTypes } from "models/returnableType";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FloatingButtonGroup } from "./ProductButton";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    position: "relative",
    borderRadius: "16px",
    height: "100%",
    width: "100%",
  },
  grid: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  gridImage: {
    [theme.breakpoints.down("md")]: {
      maxHeight: "135px",
      maxWidth: "150px",
    },
    marginTop: "20px",
    margin: "auto",
  },
  gridIcon: {
    textAlign: "right",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "4px",
    "& svg": { fontSize: "1.0rem" },
  },
  gridText: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2px",
    paddingBottom: "2px",
    textAlign: "left",
  },
  image: {
    height: "100%",
    maxHeight: "134px",
  },
  headerProduct: {
    height: "100%",
    width: "100%",
    textAlign: "center",
  },
  bottomGrid: {
    height: "100%",
    width: "100%",
    textAlign: "left",
  },
  badgeContainer: {
    textAlign: "center",
    minHeight: "29px",
    position: "absolute",
    flexWrap: "wrap",
    width: "100%",
  },
  leftBadge: {
    borderRadius: "4px",
  },
  rightBadge: {
    borderRadius: "4px",
    flexWrap: "wrap",
  },
  bodyProduct: {
    minWidth: 126,
    minHeight: "90px",
    textAlign: "left",
    width: "100%",
    height: "100%",
  },
  buttonGroup: {
    marginTop: "5px",
  },
  productButtonItem: {
    width: "52px",
    height: "52px",
  },
  floatingButtonGrid: {
    top: 110,
    right: "calc(50% - 60px)",
    position: "absolute",
    borderRadius: "16px !important",
  },
  productButton: {
    color: "#FFFFFF",
    height: "40px",
    width: "40px",
    textAlign: "center",
    padding: "6px",
    borderRadius: "16px",
    background: "black",
    cursor: "none",
  },
  productAmountButton: {
    color: "black",
    height: "40px",
    width: "40px",
    textAlign: "center",
    padding: "6px",
    borderRadius: "16px",
    background: "white",
    border: "2px solid",
    cursor: "none",
  },
  hiddenPriceGrid: {
    minHeight: 32,
  },
  gridFooter: {
    textAlign: "left",
    bottom: 4,
    position: "absolute",
  },
  productPrice: {
    marginTop: "0px",
  },
  productDiscountPrice: {
    marginTop: "0px",
    color: "#E02020",
  },
  originalPrice: {
    textDecoration: "line-through",
    color: "darkgrey",
    margingTop: "5px !important",
  },
  originalPriceGrid: {
    marginTop: 2,
  },
  returnableLiquid: {
    backgroundColor: "#9AECB8",
    color: "black",
    borderRadius: "4px",
    textTransform: "none",
    height: "100%",
    padding: "3px",
  },
  returnableLiquidText: {
    textAlign: "left",
    justifyContent: "left",
    color: "#202020",
    textTransform: "none",
    whiteSpace: "nowrap",
    lineHeight: "15px",
  },
  returnableStarterPack: {
    backgroundColor: `#FFD673`,
    color: "white",
    borderRadius: "4px",
    padding: "3px",
    textTransform: "none",
    height: "100%",
  },
  returnableStarterPackText: {
    textAlign: "left",
    justifyContent: "left",
    color: "#202020",
    textTransform: "none",
    whiteSpace: "nowrap",
    lineHeight: "15px",
  },
  discountBadge: {
    textAlign: "left",
    backgroundColor: "#e02020",
    color: "white",
    padding: "3px",
    borderRadius: "4px",
    height: "100%",
    lineHeight: "15px",
  },
  descriptionBadge: {
    padding: "3px",
  },
  descriptionBadgeText: {
    justifyContent: "left",
    fontSize: "10px",
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  divProductTitle: {
    height: "100%",
  },
  productTitle: {
    display: "table-row",
  },
  productAttributes: {
    display: "table-row",
  },
  oos: {
    backgroundColor: "rgb(230,230,230,.7)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 3,
    top: "0px",
    left: "0px",
  },
  divOos: {
    minHeight: "50px",
    height: "100%",
  },
  oosMessage: {
    left: "0px",
    color: "#BD2900",
  },
}));

export const Product = ({ product, position, viewPosition, isVisible }: { product: EcommerceProduct; position?: string; viewPosition: number, isVisible: boolean }) => {
  const classes = useStyles();
  const { accountProvider } = useSelector((state: RootState) => state.account);
  const { listName } = useSelector((state: RootState) => state.products);

  const [isViewed, setIsViewed] = useState(false);

  const isProductOutOfStock: boolean =
    (product.oos !== undefined && product.oos === "") || product.price <= 0 || !product.sku;

  if(isVisible && !isViewed) {
    handleProductView(
      product,
      position ?? listName,
      viewPosition,
      accountProvider?.country_id,
      accountProvider?.currency
    );
    setIsViewed(true);
  }

  const liquidBadge = (
    <Tooltip
      className={classes.returnableLiquid}
      title={
        <Typography variant="caption">
          El precio incluye solo líquido, no incluye envase. Si ya tienes envases retornables ahorra en cada pedido.
          Para más información consulta nuestro menú de FAQ (Retornables) o nuestras políticas de envío.
        </Typography>
      }
      placement="bottom"
      enterTouchDelay={0}
      leaveTouchDelay={3000}
    >
      <Typography variant="subtitle2" className={classes.returnableLiquidText}>
        Solo Líquido
      </Typography>
    </Tooltip>
  );

  const starterPackBadge = (
    <Tooltip
      className={classes.returnableStarterPack}
      title={
        <Typography variant="caption">
          El precio incluye líquido más envase. Para más información consulta nuestro menú de FAQ (Retornables) o
          nuestras políticas de envío.
        </Typography>
      }
      placement="bottom"
      enterTouchDelay={0}
      leaveTouchDelay={3000}
    >
      <Typography variant="subtitle2" className={classes.returnableStarterPackText}>
        Líquido + Envase
      </Typography>
    </Tooltip>
  );

  const descriptionBadge= (
    <Grid container>
      <Grid className={classes.gridIcon} item xs={1} sm={1} md={1}>
        <InfoIcon />
      </Grid>
      <Grid className={classes.gridText} item xs={11} sm={11} md={11}>
        <Tooltip
          className={classes.descriptionBadge}
          title={
            <Typography variant="caption">
              {product.long_description}
            </Typography>
          }
          placement="bottom"
          enterTouchDelay={0}
          leaveTouchDelay={3000}
        >
          <Typography variant="subtitle1" className={classes.descriptionBadgeText}>
            Ver más información
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const badges: (JSX.Element | undefined)[] = [
    product.discountPercent > 0 ? (
      <Typography variant="subtitle2" className={classes.discountBadge}>
        {product.discountPercent}% Menos!
      </Typography>
    ) : undefined,
    product.returnable_type === ReturnableTypes.Liquid ? liquidBadge : undefined,
    product.returnable_type === ReturnableTypes.StarterPack ? starterPackBadge : undefined,
  ].filter((elem) => elem);

  const footerBadges: (JSX.Element | undefined)[] = [
    product.long_description && product.packaging === "1 Paquete" ? descriptionBadge : undefined,
  ].filter((elem) => elem);
  
  return (
    <Card key={product.product_id} className={classes.card} variant="outlined">
      {isProductOutOfStock ? <div className={classes.oos}></div> : null}
      <Grid className={classes.grid} container direction="column">
        <Grid item className={classes.headerProduct} container direction="column">
          <Grid item className={classes.badgeContainer} container direction="row" spacing={1}>
            <Grid className={classes.leftBadge} item>
              {badges.length > 0 && badges[0]}
            </Grid>
            <Grid item className={classes.rightBadge}>
              {badges.length > 1 && badges[1]}
            </Grid>
          </Grid>

          <Grid item className={classes.gridImage}>
            <img className={classes.image} src={product.image_url} alt={product.name} />
          </Grid>

          <Grid item className={classes.floatingButtonGrid}>
            <FloatingButtonGroup product={product} position={position} viewPosition={viewPosition} closeDelay={3000} sucessAnimationDelay={1000} />
          </Grid>
        </Grid>
        <Grid item className={classes.bodyProduct}>
          <Grid container className={classes.divProductTitle}>
            <Grid item>
              <Typography variant="body1" className={classes.productTitle}>{product.shortname}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" className={classes.productAttributes}>
              {
              product.packaging !== "" && product.net_content!== "" ? product.packaging +" ("+product.net_content+")": 
              product.packaging !== "" ? product.packaging : null
              }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.bottomGrid}>
          {isProductOutOfStock && (
            <Grid className={classes.divOos}>
              <Typography variant="subtitle2" className={classes.oosMessage}>
                Lo sentimos, el producto esta agotado temporalmente
              </Typography>
            </Grid>
          )}

          <Grid className={classes.hiddenPriceGrid} />

          <Grid className={classes.gridFooter} container direction="row" spacing={1}>
            {footerBadges.length > 0 && footerBadges[0]}
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={product.discountPercent > 0 ? classes.productDiscountPrice : classes.productPrice}>
                  {accountProvider?.currency_symbol}
                  {formatNumber(
                    EcommerceProduct.totalPrice(product),
                    accountProvider?.amount_format,
                    accountProvider?.amount_separator
                  )}
                </Typography>
              </Grid>
              {product.discountPercent > 0 && (
                <Grid item className={classes.originalPriceGrid}>
                  <Typography variant="body2" className={classes.originalPrice}>
                    {accountProvider?.currency_symbol}
                    {formatNumber(
                      EcommerceProduct.totalPrice(product) + EcommerceProduct.totalDiscount(product),
                      accountProvider?.amount_format,
                      accountProvider?.amount_separator
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
