import React, { useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import {
  makeStyles,
  Typography,
  Button
} from "@material-ui/core";
import "../../assets/pdfViewer.css";

import chatbotPromoPDF from '../../assets/BasesChatbot.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export enum PDFType {
  ChatbotPromo = 1,
  TermsConditions = 2,
  PrivacyPolicy = 3
}

const useStyles = makeStyles((theme) => ({
  pdfActiveButton: {
    margin: theme.spacing(2),
    backgroundColor: "#FA0009",
    color: "#fafafa",
  },
  pdfDisableButton: {
    margin: theme.spacing(2),
    backgroundColor: "#b2b2b2",
    color: "#000",
  },
  pdfContainer: {

  }
}));

export const PDF = ({ type }: { type: number }) => {
  const classes = useStyles();

  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages } : { numPages:number }) {
    setNumPages(numPages);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function selectPDF(type: PDFType) {
    switch(type) {
      case PDFType.ChatbotPromo:
        return chatbotPromoPDF;
    }
  }

  return (
    <>
      <div className="pdf__container__document">
        <Document
          file={ selectPDF(type) }
          options={{ workerSrc: pdfjs }}
          onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>

      <div className={"paginator"}>
        <Typography>
          Página {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </Typography>
        <Button
          type="button"
          className={ (pageNumber <= 1) ? classes.pdfDisableButton: classes.pdfActiveButton }
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Anterior
        </Button>
        <Button
          disabled={pageNumber >= numPages}
          className={ (pageNumber >= numPages) ? classes.pdfDisableButton: classes.pdfActiveButton }
          onClick={nextPage}
        >
          Siguiente
        </Button>
      </div>
    </>
  );
};
