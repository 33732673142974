import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EcommerceAddress } from 'models/ecommerceAddress';
interface DeliveryState {
  deliveryData: EcommerceAddress;
  billingInformation?: BillingInformation;
  date: string;
  selectedAddress: string;
  min_day: string;
  max_day: string;
  disabled_dates: string[];
  disabled_days: string[];
  selectedDeliveryDate: string;
  selectedPaymentMethod: string;
  selectedAddressIndex: number | null;
  deliveryAddresses?: Address[];
}

const initialState: DeliveryState = {
  deliveryData: EcommerceAddress.default(),
  date: '',
  selectedAddress: '',
  min_day: '',
  max_day: '',
  disabled_dates: [''],
  disabled_days: [''],
  selectedDeliveryDate: '',
  selectedPaymentMethod: '',
  selectedAddressIndex: null,
  deliveryAddresses: undefined,
};

const delivery = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setDeliveryInitialState: (state: DeliveryState, action: PayloadAction<void>) => {
      return initialState;
    },
    setDeliveryDate: (state: DeliveryState, action: PayloadAction<EcommereceDeliveryDate>) => {
      state.date = action.payload.delivery_day;
      state.min_day = action.payload.min_day;
      state.max_day = action.payload.max_day;
      state.disabled_dates = action.payload.disabled_dates;
      state.disabled_days = action.payload.disabled_days;
    },
    setDeliveryData: (state: DeliveryState, action: PayloadAction<EcommerceAddress>) => {
      state.deliveryData = action.payload;
      state.selectedAddress = EcommerceAddress.toString(action.payload);
    },
    setBillingInformation: (state: DeliveryState, action: PayloadAction<BillingInformation>) => {
      state.billingInformation = action.payload;
    },
    setSelectedDeliveryDate: (state: DeliveryState, action: PayloadAction<string>) => {
      state.selectedDeliveryDate = action.payload;
    },
    setSelectedPaymentMethod: (state: DeliveryState, action: PayloadAction<string>) => {
      state.selectedPaymentMethod = action.payload;
    },
    setSelectedAddressIndex: (state: DeliveryState, action: PayloadAction<number | null>) => {
      state.selectedAddressIndex = action.payload;
    },
    setDeliveryAddresses: (state: DeliveryState, action: PayloadAction<Address[]>) => {
      state.deliveryAddresses = action.payload;
    },
  },
});

export const {
  setDeliveryInitialState,
  setDeliveryData,
  setDeliveryDate,
  setBillingInformation,
  setSelectedDeliveryDate,
  setSelectedPaymentMethod,
  setSelectedAddressIndex,
  setDeliveryAddresses,
} = delivery.actions;

export const deliveryReducer = delivery.reducer;
