import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import {
  funcFinishFlow,
  funcOrderCheckout, funcPaymentPostVerify,
} from "features/common/API";
import {
  CssBaseline,
} from "@material-ui/core";
import { FullPageLoader, LoaderType } from "features/main/FullPageLoader";
import { BotWhatsAppLink, ProductsRelativeLink } from "features/common/urlBuilder";
import { MessageDialog } from "features/main/MessageDialog";
import { clearCart, removeAllProducts } from "features/common/productSlice";

export const PaymentPage = () => {
  const { conversationContext, registerAccount, accountProvider } = useSelector((state: RootState) => state.account);
  const { selectedDeliveryDate, billingInformation } = useSelector((state: RootState) => state.deliveryData);
  const { cart, subtotal } = useSelector((state: RootState) => state.products);
  const { deliveryData, selectedPaymentMethod } = useSelector((state: RootState) => state.deliveryData);

  const dispatch: AppDispatch = useDispatch();
  const [isLoaderActive, setIsLoaderActive] = useState(true);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  let searchParams = new URLSearchParams(window.location.search);
  let token = searchParams.get("token") || '';

  useEffect(() => {
    (async () => {
      if (token) {
        const paymentResult = await funcPaymentPostVerify(conversationContext?.accountProviderId as number, registerAccount?.account_id as string, token);
        if (paymentResult && paymentResult.transaction_status === 'approved') {
          // Do Checkout
          window.onbeforeunload = () => true;
          const cartProducts = Object.keys(cart).map((id) => cart[id]);
          var checkoutResponse = await funcOrderCheckout(
            conversationContext?.accountProviderId as number,
            registerAccount?.account_id as string,
            cartProducts,
            deliveryData,
            subtotal,
            billingInformation!,
            selectedDeliveryDate,
            selectedPaymentMethod,
            paymentResult
          );

          if (typeof checkoutResponse.status === "string") {
            //CheckoutOrder gives OK
            const result = checkoutResponse as EcommerceOrder;

            const CheckoutResult = {
              products: cartProducts,
              transactionId: result.supplier_order_id,
              countryId: accountProvider?.country_id,
              currency: accountProvider?.currency,
            };
            dispatch(clearCart(CheckoutResult));
            var contextId: string = conversationContext?.contextId as string;

            window.onbeforeunload = () => undefined;
            window.location.replace(BotWhatsAppLink(conversationContext?.whatsappNumber));
            return;
          } else {
            //CheckoutOrder gives not OK
            window.onbeforeunload = () => undefined;
            if (checkoutResponse && checkoutResponse.status === 400) {
              var errorMessage: string = (checkoutResponse as ErrorRequestResponse).data.error.toString();
              var productName = errorMessage.slice(errorMessage.indexOf("name ") + 5);
              if (productName) {
                setIsErrorMessageOpen(true);
                setErrorMessage(`Lo sentimos 😔, el producto ${productName} esta agotado temporalmente.`);
                setErrorMessageButtonLink("");
                setErrorMessageButtonText("Ok");

                const productToRemoveIndex = cartProducts.findIndex((product) => product.name === productName);
                if (productToRemoveIndex >= 0) {
                  const productToRemove = cartProducts[productToRemoveIndex];
                  dispatch(
                    removeAllProducts({
                      product: productToRemove!,
                      position: "Checkout",
                      viewPosition: productToRemoveIndex + 1,
                      countryId: accountProvider?.country_id,
                      currency: accountProvider?.currency,
                    })
                  );
                }
              }
              return;
            } 
          }
        }
      }

      setIsLoaderActive(false)
      setIsErrorMessageOpen(true);
      setErrorMessage("El pago no ha sido procesado correctamente. Intentalo nuevamente o selecciona otro método de compra");
      setErrorMessageButtonLink(ProductsRelativeLink()+"?contextId="+conversationContext?.contextId);
      setErrorMessageButtonText("Regresar al carrito");
    })();
  }, []);
  return (
    <>
      <CssBaseline/>
      {isLoaderActive ?<FullPageLoader type={LoaderType.Authentication} text={"Procesando pago... Por favor permanece en esta página hasta que finalice la operación."} /> : <></>}
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  )
};
